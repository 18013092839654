import jsPDF from 'jspdf';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { ChevronRight, Edit2, Plus, Printer } from 'react-feather';
import { useTranslation } from 'react-i18next';
import BrandImage from '../../../assets/logo.png';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import { GrayButton, SuccessButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { CategoryForm } from '../../../components/form/CategoryForm';
import { CategoryUpdateForm } from '../../../components/form/CategoryUpdateForm';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { PrimaryModal } from '../../../components/modal/PrimaryModal';
import { Text } from '../../../components/text/Text';
import { Toastify } from '../../../components/toastify/Toastify';
import { Requests } from '../../../utils/Http/Index';
import { getCurrentDateTime } from '../../../utils/_heplers';

const Category = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [data_loading, setDataLoading] = useState(true);
  const [isCreate, setCreate] = useState({ show: false, loading: false });
  const [isUpdate, setUpdate] = useState({
    value: {},
    show: false,
    loading: false,
  });
  const [activeKey, setActiveKey] = useState(null);
  const [error, setError] = useState(false);

  const handleGeneratePDF = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');

    const pdf = new jsPDF();
    const columns = ['Category Name'];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 20;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY + 6, 'CATEGORY DATA');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: categories.map((row) => [row.name || 'N/A']),
      styles: {
        //   fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
        textColor: [17, 35, 90], // RGB color for text (adjust as needed)
        halign: 'center', // Horizontal alignment: 'left', 'center', 'right'
        valign: 'middle', // Vertical alignment: 'top', 'middle', 'bottom'
        fontSize: 12, // Font size for the table content
        cellPadding: 2, // Padding inside each cell
        borderBottom: '1px solid black',
        rowStyles: (rowIndex) => {
          // Borders at the top and bottom for each row
          return {
            borderTop: rowIndex === 0 ? '1px solid black' : 'none',
            borderBottom: '1px solid black',
          };
        },
      },
      headStyles: {
        fillColor: [34, 50, 100], // RGB color for header background
        textColor: [255, 255, 255], // RGB color for header text
        fontSize: 10, // Font size for the header
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Set width for the first column
        1: { cellWidth: 'auto' }, // Set auto width for the second column
        // Add more column styles as needed
      },
    });

    pdf.save('category_data.pdf');
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Category.CategoryList();
      console.log(response.data.data);
      if (response.status === 200) setCategories(response.data.data);
      setDataLoading(false);
    } catch (error) {
      if (error) {
        setError(true);
        setDataLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const toggleActive = (key) => {
    if (activeKey === key) {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const handleCategoryCreate = async (data) => {
    data.dokan_uid = localStorage.getItem('dokanuid');
    try {
      const response = await Requests.Inventory.Category.CategoryAdd(data);
      if (response.status === 201) {
        if (data.category_uid) {
          Toastify.Success(t('Sub Category Created Successfully'));
        } else {
          Toastify.Success(t('Category Created Successfully'));
        }

        fetchCategories();
      }
      setCreate({ show: false, loading: false });
    } catch (error) {
      if (error) {
        setCreate({ show: false, loading: false });
        if (error && error.response && error.response.status === 422) {
          if (data.category_uid || data.category_uid === '') {
            Toastify.Error(t("SubCategory Can't Be Created"));
          } else {
            Toastify.Error(t("Category Can't Be Created"));
          }
        } else {
          Toastify.Error(t('Network Error'));
        }
      }
    }
  };

  const handleCategoryUpdate = async (data) => {
    try {
      setUpdate({ ...isUpdate, loading: true });

      data.dokan_uid = localStorage.getItem('dokanuid');
      const response = await Requests.Inventory.Category.CategoryUpdate(data);
      if (response.status === 200) {
        setUpdate({ show: false, loading: false });
        fetchCategories();

        if (data.category_uid !== 0) {
          Toastify.Success(t('Sub Category Updated Successfully'));
        } else {
          Toastify.Success(t('Category Updated Successfully'));
        }
      }
    } catch (error) {
      setUpdate({ ...isUpdate, loading: false });
      if (error && error.response && error.response.status === 422) {
        if (data.category_uid !== 0) {
          Toastify.Success(t('Sub Category Updated Successfully'));
        } else {
          Toastify.Success(t('Category Updated Successfully'));
        }
      } else {
        Toastify.Error(t('Network Error'));
      }
    }
  };

  return (
    <div>
      <Layout
        page='inventory / category list'
        message={t('Category of product usually you sell.')}
        container='container-fluid'
        button={
          <div>
            <GrayButton
              onClick={() => setCreate({ show: true, loading: false })}
            >
              <Plus size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('ADD NEW')}</span>
            </GrayButton>

            <GrayButton
              onClick={handleGeneratePDF}
              className='ml-2 mt-2 mt-sm-0'
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      />
      <Main>
        {data_loading && !categories.length && !error ? <Loader /> : null}
        {!data_loading && !categories.length && error ? (
          <NetworkError message={t('Network error.')} />
        ) : null}
        {!data_loading && !categories.length && !error ? (
          <NoContent message={t('No category available.')} />
        ) : null}

        {!data_loading && !error && categories.length ? (
          <Container.Column className='col-lg-10 mb-10'>
            <Accordion>
              {categories &&
                categories.map((item, i) => (
                  <Card className='rounded-2' key={i}>
                    <Card.Header
                      style={{ border: 'none' }}
                      className='px-2 bg-white'
                      onClick={() => toggleActive(i)}
                    >
                      {item.subcategories.length > 0 ? (
                        <div className='d-flex'>
                          <Accordion.Toggle
                            as={Button}
                            variant='text'
                            eventKey={i + 1}
                            className='w-100 shadow-none text-decoration-none text-dark'
                          >
                            <div className='d-flex flex-column justify-content-center align-items-start'>
                              <div>
                                <Text className='fs-15 fw-bold mb-0'>
                                  {t(item.name)}
                                </Text>
                              </div>
                              <div>
                                <Text className='fs-12 mb-0'>
                                  Sub Category - {item.subcategories.length}
                                </Text>
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <div className='ml-auto'>
                            <SuccessButton
                              onClick={() => {
                                setUpdate({
                                  value: item,
                                  show: true,
                                  loading: false,
                                });
                              }}
                              className='success-add-circle'
                            >
                              <Edit2 size={18} />
                            </SuccessButton>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex'>
                          <Accordion.Toggle
                            as={Button}
                            variant='text'
                            className='w-100 shadow-none text-decoration-none text-dark'
                          >
                            <div className='d-flex'>
                              <div>
                                <Text className='fs-15 mb-0'>
                                  {t(item.name)}
                                </Text>
                              </div>
                            </div>
                          </Accordion.Toggle>
                          <div className='ml-auto'>
                            <SuccessButton
                              onClick={() => {
                                setUpdate({
                                  value: item,
                                  show: true,
                                  loading: false,
                                });
                              }}
                              className='success-add-circle'
                            >
                              <Edit2 size={18} />
                            </SuccessButton>
                          </div>
                        </div>
                      )}
                    </Card.Header>
                    <Accordion.Collapse eventKey={i + 1}>
                      <Card.Body>
                        {item.subcategories.length > 0 ? (
                          <Text className='fs-14 font-weight-bold'>
                            Sub Categories
                          </Text>
                        ) : null}
                        {item.subcategories &&
                          item.subcategories.map((subitem, j) => {
                            return (
                              <div className='d-flex' key={j}>
                                <div>
                                  <Text className='fs-13 mb-0'>
                                    <ChevronRight size={15} />
                                    {t(subitem.name)}
                                  </Text>
                                </div>
                                <div className='ml-auto pb-1'>
                                  <SuccessButton
                                    className='success-add-circle'
                                    onClick={() => {
                                      setUpdate({
                                        value: subitem,
                                        show: true,
                                        loading: false,
                                      });
                                    }}
                                  >
                                    <Edit2 size={18} />
                                  </SuccessButton>
                                </div>
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
            </Accordion>
          </Container.Column>
        ) : null}
      </Main>

      {/* Product category create */}
      <PrimaryModal
        show={isCreate.show}
        onHide={() => setCreate({ show: false, loading: false })}
        title={t('Add New Category')}
        size='md'
      >
        <CategoryForm
          submit={handleCategoryCreate}
          categories={categories}
          update={false}
        />
      </PrimaryModal>

      {/* Product category update */}
      {isUpdate.value && isUpdate.show ? (
        <PrimaryModal
          show={isUpdate.show}
          onHide={() => setUpdate({ show: false, loading: false })}
          title={
            isUpdate.value && isUpdate.value.category_uid === 0
              ? t('Update Category')
              : t('Update SubCategory')
          }
          size='md'
        >
          <CategoryUpdateForm
            loading={isUpdate.loading}
            submit={handleCategoryUpdate}
            categories={categories}
            categorydata={isUpdate.value}
            update={true}
          />
        </PrimaryModal>
      ) : null}
    </div>
  );
};

export default Category;
