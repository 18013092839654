import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { NetworkError } from '../../components/501/NetworkError';
import { DatePicker } from '../../components/datePicker/Index';
import { Container } from '../../components/container/Index';
import { Layout, Main } from '../../components/layout/Index';
import { SingleSelect } from '../../components/select/Index';
import { NoContent } from '../../components/204/NoContent';
import { DataTable } from '../../components/table/Index';
import { Loader } from '../../components/loading/Index';
import { Card } from '../../components/card/Index';
import { Text } from '../../components/text/Text';
import { Requests } from '../../utils/Http/Index';
import axiosInstance from '../../utils/Http/axiosInterceptor';
import { GrayButton } from '../../components/button/Index';
import { Printer } from 'react-feather';
import { dateFormate, getCurrentDateTime } from '../../utils/_heplers';
import jsPDF from 'jspdf';
import moment from 'moment';
import { size } from 'lodash';

const Customer = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [fromdate, setFromdate] = useState(new Date());
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [singleCustomer, setSingleCustomer] = useState({});
  const [error, setError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [searchLoading, setsearchLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [brandData, setBrandData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [toDate, setToDate] = useState(null);
  const handleGeneratePDF = () => {
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');

    const pdf = new jsPDF();
    const columns = [
      'Name',
      'Code',
      'Quantity',
      'Purchase Price',
      'Selling Price',
      'Total Price',
      'Warrenty Type',
    ];

    const imageX = pdf.internal.pageSize.width - 30;
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165;
    const textY = 18;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15);
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(12);
    pdf.text(textX, textY + 6, 'STOCK DATA');

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10;
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns],
      body: data.map((row) => [
        row.name,
        row.product_code
          ? row.product_code
          : row.codes.map((code) => code.code).join(', ') || 'N/A',
        row.quantity ?? '0',
        row.purchase_price ?? '0',

        row.selling_price || '0',
        row.purchase_price * row.quantity || 0,
        row.warranty_type ? row.warranty_type : '0',
      ]),
      styles: {
        font: 'RobotoCondensedBold',
        halign: 'center', // Align text to the center
        cellPadding: 2, // Adjust cell padding for better spacing
        lineColor: [0, 0, 0], // Set border color to black
        lineWidth: 0.5, // Set border width
      },
      headStyles: {
        fillColor: [22, 66, 60], // Set the header background color
        textColor: [255, 255, 255], // Set the header text color to white
        lineColor: [0, 0, 0], // Set header border color to black
        lineWidth: 0.2, // Set header border width
      },
      bodyStyles: {
        fillColor: [255, 255, 255], // Make the background white
        lineColor: [196, 218, 210], // Set body border color to black
        lineWidth: 0.2, // Set body border width
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255], // Ensure no stripes
      },
      margin: { top: 10 },
    });

    pdf.save(`stock-${getCurrentDateTime()}.pdf`);
  };

  // category data
  const fetchCategories = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Category.CategoryList();
      const data = [];
      for (let i = 0; i < response.data.data?.length; i++) {
        data.push({
          value: response.data.data[i].uid,
          label: response.data.data[i].name,
        });
      }
      setCategoryData(data);
    } catch (error) {
      if (error) console.log(error);
    }
  }, []);

  // brand data
  const fetchBrands = useCallback(async () => {
    const response = await Requests.Inventory.Brand.DokanBrandList();
    if (response && response.status === 200) {
      const data = [];
      for (let i = 0; i < response.data.data?.length; i++) {
        data.push({
          value: response.data.data[i].uid,
          label: response.data.data[i].name,
        });
      }
      setBrandData(data);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await Requests.Inventory.Stock.StockStatus(
          page,
          perPage
        );
        if (response && response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.meta.total);

          console.log(response.data.data);
        }

        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
        }
      }
    },
    [perPage]
  );

  const fetchUsersWithQuery = useCallback(async () => {
    setLoading(true);

    try {
      const response = await Requests.Inventory.Stock.StockStatus(
        totalRows,
        perPage,
        category,
        brand
      );
      setData(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
      // Handle error
      console.error('Error fetching accounts with query:', error);
      // setError(true);
    } finally {
      setLoading(false);
    }
  }, [perPage, totalRows, category, brand]);

  useEffect(() => {
    if (category || brand) {
      // If date and toDate are set, fetch with query parameters
      fetchUsersWithQuery();
    } else {
      // Otherwise, fetch without query parameters
      fetchData();
    }
  }, [fetchData, fetchUsersWithQuery, brand, category]);

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await Requests.Inventory.Stock.StockStatus(
      page,
      newPerPage
    );
    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  // Handle search
  const handleSearch = async (data) => {
    setsearchLoading(true);
    try {
      const response = await Requests.Inventory.Stock.StockStatusSearch(data);
      if (response.data && response.status === 200) setData(response.data.data);
      setsearchLoading(false);
    } catch (error) {
      if (error) {
        setsearchLoading(false);
        setServerError(true);
      }
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name || 'N/A',
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row) =>
        row.product_code
          ? row.product_code
          : row.codes.map((code) => code.code).join(', ') || 'N/A',
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity || '0',
      sortable: true,
    },
    {
      name: 'Purchase Price (Per Unit)',
      selector: (row) => row.purchase_price || 0,
      sortable: true,
    },
    {
      name: 'Sell Price',
      selector: (row) => row.selling_price || 0,
      sortable: true,
    },
    {
      name: 'Total Price',
      selector: (row) => row.purchase_price * row.quantity || 0,
      sortable: true,
    },
    {
      name: 'Warranty Type',
      selector: (row) => (row.warranty_type ? row.warranty_type : 0),
      sortable: true,
    },
  ];

  return (
    <div>
      <Layout
        page='report / stock report'
        message='Stock Report for your shop'
        container='container-fluid'
        button={
          <div>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      >
        <Main>
          <Container.Column className='pr-4 mb-2'>
            <div className='d-sm-flex justify-content-between align-items-end pr-2'>
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 200 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Select Category')}
                  </Text>
                  <SingleSelect
                    borderRadius={30}
                    placeholder='Category'
                    options={categoryData}
                    value={(event) => setCategory(event.value)}
                  />
                </FormGroup>
              </div>

              {/* Brand options */}
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 200 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Select Brand')}
                  </Text>
                  <SingleSelect
                    borderRadius={30}
                    placeholder='Brand'
                    options={brandData}
                    value={(event) => setBrand(event.value)}
                  />
                </FormGroup>
              </div>

              {/* Search Field */}
              <div style={{ flexGrow: 1 }}>
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Search')}
                  </Text>
                  <input
                    type='text'
                    className='form-control  w-25 rounded-pill'
                    placeholder={t('Search Customer')}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </FormGroup>
              </div>
            </div>
          </Container.Column>
          {loading && !data?.length ? <Loader /> : null}
          {!loading && error && !data?.length ? (
            <NetworkError message='Network Error.' />
          ) : !loading && !data?.length ? (
            <NoContent message='No Content Found!' />
          ) : (
            <>
              <Container.Column className='pl-0'>
                <DataTable
                  columns={columns}
                  data={data}
                  loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  // searchable
                  // placeholder={'Search Supplier'}
                  // search={handleSearch}
                  // suggestion={handleSuggestion}
                  // searchLoading={searchLoading}
                  clearSearch={() => fetchData(1)}
                />
              </Container.Column>
            </>
          )}
        </Main>
      </Layout>
    </div>
  );
};

export default Customer;
