import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../button/Index';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { Container } from '../container/Index';
import { FormGroup } from '../formGroup/FormGroup';
import { Text } from '../text/Text';
import './style.scss';
import { SingleSelect } from '../select/Index';
import { DatePicker } from '../datePicker/Index';
import { Requests } from '../../utils/Http/Index';
import { Toastify } from '../toastify/Toastify';
import { Form } from 'react-bootstrap';
import { dateYearFormat } from '../../utils/_heplers';
import moment from 'moment';

export const SupplierBillFormWithId = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const supplier = props.supplier ?? {};
  const { loading } = props ?? {};

  console.log(props?.suppliers, 'pib...');
  console.log(props?.supplier?.uid, 'sub...');
  console.log(props?.supplier?.name, 'sub...');
  const [dateDate, setDateDate] = useState('');

  // Submit Form
  const submit = async (data) => {
    console.log(data);
    if (!dateDate) {
      setError('pay_date', {
        type: 'manual',
        message: 'Date is required.',
      });
    }

    // clearErrors()
    const formData = {
      ...data,
      pay_date: dateYearFormat(dateDate),
    };

    if (dateDate) {
      props.submit(formData);
    }
  };

  useEffect(() => {
    if (props.supplier) {
      setValue('supplier_uid', props?.supplier?.uid);
    }
  }, [props, setValue]);

  // console.log(
  //   moment(props.supplier.pay_date.split(' ')[0]).format('DD-MM-YY'),
  //   'hii'
  // );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Container.Row>
        <Container.Column className='col-lg-6'>
          <FormGroup>
            {/* Supplier */}
            {errors.supplier_uid && errors.supplier_uid.message ? (
              <Text className='text-danger fs-13 mb-1'>
                {errors.supplier_uid && errors.supplier_uid.message}
              </Text>
            ) : (
              <Text className='text-capitalized fs-13 mb-1'>
                {t('Select Supplier')} <span className='text-danger'>*</span>
              </Text>
            )}
            {props.supplier && (
              <Controller
                name='supplier_uid'
                control={control}
                rules={{ required: t('Supplier is Required') }}
                render={({ field }) => (
                  <SingleSelect
                    error={errors.supplier}
                    deafult={
                      props?.supplier?.uid
                        ? {
                            label: props?.supplier?.name,
                            value: props?.supplier?.uid,
                          }
                        : null
                    }
                    placeholder='a supplier'
                    options={props.suppliers}
                    value={(event) =>
                      setValue('supplier_uid', event.value, {
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />
            )}
          </FormGroup>
        </Container.Column>

        {/* give amount */}
        <Container.Column className='col-lg-6'>
          <FormGroup>
            {errors.amount && errors.amount.message ? (
              <Text className='text-danger fs-13 mb-1'>
                {errors.amount && errors.amount.message}
              </Text>
            ) : (
              <Text className='text-capitalized fs-13 mb-1'>
                {t('Give Amount')} <span className='text-danger'>*</span>
              </Text>
            )}

            <input
              type='number'
              className={
                errors.amount
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder='Enter Amount'
              defaultValue={
                supplier && supplier.pay_amount ? supplier.pay_amount : ''
              }
              {...register('amount', {
                required: t('Amount is required'),
              })}
            />
          </FormGroup>
        </Container.Column>

        {/* Date Time */}
        <Container.Column className='col-lg-6'>
          <FormGroup>
            <Text className='fs-13 mb-1'>
              Date <span className='text-danger'> *</span>
            </Text>
            <DatePicker
              selected={(data) => {
                setDateDate(data);
              }}
              deafultValue={dateDate && dateDate}
            />
          </FormGroup>
        </Container.Column>

        {/* invoice no */}
        <Container.Column className='col-lg-6'>
          <FormGroup>
            {errors.invoice_no && errors.invoice_no.message ? (
              <Text className='text-danger fs-13 mb-1'>
                {errors.invoice_no && errors.invoice_no.message}
              </Text>
            ) : (
              <Text className='text-capitalized fs-13 mb-1'>
                {t('Invoice No')}
              </Text>
            )}

            <input
              type='text'
              className={
                errors.invoice_no
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder='Enter Invoice No'
              defaultValue={
                supplier && supplier.invoice_no ? supplier.invoice_no : ''
              }
              {...register('invoice_no')}
            />
          </FormGroup>
        </Container.Column>
        <Container.Column className='d-flex'>
          <Form.Check
            custom
            type='checkbox'
            name='prev_consider'
            id={`prev_consider`}
            defaultChecked={
              supplier && supplier.prev_consider == 1 ? true : false
            }
            style={{ fontSize: 14 }}
            {...register('prev_consider')}
          />
          <Text>Is All Amount Paid ?</Text>
        </Container.Column>

        <Container.Column className='text-right'>
          <PrimaryButton type='submit' className='px-4' disabled={loading}>
            {props.supplier ? (
              <span>{loading ? 'Updating ...' : 'Update'}</span>
            ) : (
              <span>{loading ? 'Creating ...' : 'Create'}</span>
            )}
          </PrimaryButton>
        </Container.Column>
      </Container.Row>
    </form>
  );
};
