import React, { useState } from 'react';
import './style.scss';
import arrow from './chevron-right.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Menu Item
const MenuItem = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className='sub-menu-container '>
      {props.path ? (
        <NavLink
          exact
          to={props.path}
          type='button'
          activeClassName='isActive'
          className={`btn shadow-none menu-btn ${
            props.isStatic ? 'bg-gray' : 'glass-color'
          }`}
        >
          <span className='menu-icon'>{props.icon}</span>
          <span className='menu-title'>{t(props.title)}</span>
        </NavLink>
      ) : (
        <div
          className={`sub-menu btn  d-flex ${
            props.isStatic ? 'bg-gray' : 'glass-color'
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className={`${props.isStatic ? 'text-white' : ''} menu-icon`}>
            {props.icon}
          </div>
          <div className={`${props.isStatic ? 'text-white' : ''} menu-title`}>
            {t(props.title)}
          </div>
          {/* className={open ? 'arrow down' : 'arrow'} */}
          {props.children && (
            <div className='menu-arrow ml-auto'>
              {open ? (
                <>
                  {' '}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    class='bi bi-chevron-down'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    class='bi bi-chevron-right'
                    viewBox='0 0 16 16'
                  >
                    <path
                      fill-rule='evenodd'
                      d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'
                    />
                  </svg>
                </>
              )}
            </div>
          )}
          {props.isStatic && (
            <div className='menu-arrow ml-auto'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                class='bi bi-arrow-right'
                viewBox='0 0 16 16'
              >
                <path
                  fill-rule='evenodd'
                  d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
                />
              </svg>
            </div>
          )}
        </div>
      )}

      <div className={open ? 'sub-menu-body show' : 'sub-menu-body'}>
        {props.children &&
          props.children.map((item, i) =>
            item.inDrawer ? (
              <MenuItem
                key={i}
                icon={item.icon}
                path={item.path}
                title={t(item.title)}
                children={item.children}
                isStaic={item.isStatic}
              />
            ) : null
          )}
      </div>
    </div>
  );
};

const TreeMenu = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      {props.options && props.options.length
        ? props.options.map((item, i) => {
            if (item.name && item.inDrawer) {
              return (
                <MenuItem
                  key={i}
                  icon={item.icon}
                  path={item.path}
                  title={t(item.title)}
                  children={item.children}
                  isStatic={item.isStatic}
                />
              );
            } else {
              return null;
            }
          })
        : null}
    </div>
  );
};

export { MenuItem, TreeMenu };
