import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Eye, Printer } from 'react-feather';
import { Layout, Main } from '../../components/layout/Index';
import { DataTable } from '../../components/table/Index';
import { Requests } from '../../utils/Http/Index';
import { Container } from '../../components/container/Index';
import { GrayButton, SuccessButton } from '../../components/button/Index';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { Loader } from '../../components/loading/Index';
import { NoContent } from '../../components/204/NoContent';
import { NetworkError } from '../../components/501/NetworkError';
import { dateFormat2, getCurrentDateTime } from '../../utils/_heplers';
import BrandImage from '../../assets/logo.png';

//for pdf
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { SingleSelect } from '../../components/select/Index';
import { size } from 'lodash';
import { Text } from '../../components/text/Text';
import { DatePicker } from '../../components/datePicker/Index';
import moment from 'moment';
const ProductReturnAndReplacement = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchLoading, setSearchLoading] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [date, setDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState('');

  const handleGeneratePDF = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');
    const pdf = new jsPDF();
    const columns = [
      'Invoice',
      'Customer Name',
      'Customer Phone',
      'Return Date',
      'Return Quantity',
      'Return Amount',
    ];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 18;
    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY + 6, 'RETURN AND REPLACEMENT ');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: data.map((row) => [
        row.uid ? row.uid : 'N/A',
        row.customer_info?.name ? row.customer_info?.name : 'N/A',
        row.customer_info?.phone ? row?.customer_info?.phone : 'N/A',
        row.return_replace_date ? dateFormat2(row.return_replace_date) : 'N/A',
        row.return_quantity ? row.return_quantity : '0',
        row.return_amount ? row.return_amount : '0',
      ]),
      styles: {
        //   fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
        textColor: [17, 35, 90], // RGB color for text (adjust as needed)
        halign: 'center', // Horizontal alignment: 'left', 'center', 'right'
        valign: 'middle', // Vertical alignment: 'top', 'middle', 'bottom'
        fontSize: 12, // Font size for the table content
        cellPadding: 2, // Padding inside each cell
        borderBottom: '1px solid black',
        rowStyles: (rowIndex) => {
          // Borders at the top and bottom for each row
          return {
            borderTop: rowIndex === 0 ? '1px solid black' : 'none',
            borderBottom: '1px solid black',
          };
        },
      },
      headStyles: {
        fillColor: [34, 50, 100], // RGB color for header background
        textColor: [255, 255, 255], // RGB color for header text
        fontSize: 10, // Font size for the header
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Set width for the first column
        1: { cellWidth: 'auto' }, // Set auto width for the second column
        // Add more column styles as needed
      },
    });

    pdf.save('return_replace.pdf');
  };

  const fetchCustomerData = useCallback(
    async (page) => {
      try {
        const response = await Requests.Customer.AllCustomer(page, perPage);
        if (response.status === 200) {
          // setMechanics(response.data.data)
          let data = [];
          if (response.data.data) {
            for (let i = 0; i < response.data.data?.length; i++) {
              let elements = response.data.data[i];
              data.push({
                label: elements.name ?? '',
                value: elements.uid,
              });
            }
          }
          setCustomers(data);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );
  // Mechanic Data Call
  useEffect(() => {
    fetchCustomerData(1);
  }, [fetchCustomerData]);

  // fetch employee data
  const fetchEmployee = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await Requests.Inventory.Product.ReturnProductList(
          page,
          perPage
        );
        if (response && response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.total);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  useEffect(() => {
    fetchEmployee(1);
  }, [fetchEmployee]);

  const fetchOrdersWithQuery = useCallback(async (date, toDate, customer) => {
    setLoading(true);
    const formattedDateFrom = date ? moment(date).format('YYYY-MM-DD') : '';
    const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
    try {
      const response = await Requests.Inventory.Product.ReturnProductList(
        totalRows,
        perPage,
        formattedDateFrom,
        formattedDateTo,
        customer
      );
      setData(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
      // Handle error
      console.error('Error fetching accounts with query:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (date && toDate) {
      // If date and toDate are set, fetch with query parameters
      fetchOrdersWithQuery(date, toDate, customer);
    } else if (customer) {
      // If date and toDate are set, fetch with query parameters
      fetchOrdersWithQuery(date, toDate, customer);
    } else {
      // Otherwise, fetch without query parameters
      fetchEmployee();
    }
  }, [fetchEmployee, fetchOrdersWithQuery, customer, date, toDate]);

  // handle paginate page change
  const handlePageChange = async (page) => {
    setLoading(true);
    const response = await Requests.Inventory.Product.ReturnProductList(
      page,
      perPage
    );
    if (response && response.status === 200) {
      setData(response.data.data);
      setLoading(false);
    }
    setLoading(false);
  };

  // handle paginate row change
  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    const response = await Requests.Inventory.Product.ReturnProductList(
      page,
      newPerPage
    );
    if (response && response.status === 200) {
      setData(response.data.data);
      setPerPage(newPerPage);
    }
    setLoading(false);
  };

  // data columns
  const columns = [
    {
      name: `${t('Invoice')}`,
      selector: (row) => (row.uid ? row.uid : 'N/A'),
      sortable: true,
    },
    {
      name: `${t('Customer Name')}`,
      selector: (row) =>
        row.customer_info?.name ? row.customer_info?.name : 'N/A',
      sortable: true,
    },
    {
      name: `${t('Customer Phone')}`,
      selector: (row) =>
        row.customer_info?.phone ? row.customer_info?.phone : 'N/A',
      sortable: true,
    },
    {
      name: `${t('Return Date')}`,
      selector: (row) =>
        row.return_replace_date ? dateFormat2(row.return_replace_date) : 'N/A',
      sortable: true,
    },
    {
      name: `${t('Return Quantity')}`,
      selector: (row) => (row.return_quantity ? row.return_quantity : '0'),
      sortable: true,
    },
    {
      name: `${t('Return Amount')}`,
      selector: (row) => (row.return_amount ? row.return_amount : '0'),
      sortable: true,
    },
    // {
    //   name: `${t('Due')}`,
    //   selector: (row) => (row.monthly_salary ? row.monthly_salary : '0'),
    //   sortable: true,
    // },
    // {
    //   name: `${t('Action')}`,
    //   grow: 0,
    //   minWidth: '150px',
    //   cell: (row) => (
    //     <div>
    //       <Link to={`/dashboard/product-return-replacement/show/${row.uid}`}>
    //         <SuccessButton
    //           type='button'
    //           style={{
    //             borderRadius: '50%',
    //             padding: '6px 9px',
    //             marginRight: 5,
    //           }}
    //           // onClick={() => handleAction(row)}
    //         >
    //           <Eye size={16} />
    //         </SuccessButton>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <Layout
        page={t('dashboard / return & replacement history ')}
        message={t(
          'Customer list those are eligible to product return and replace'
        )}
        container='container-fluid'
        button={
          <div>
            <GrayButton
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      />

      <Main>
        <Container.Column className='pr-4 mt-2 mb-2'>
          <div className='d-sm-flex justify-content-end pr-2'>
            {/* Mechanic options */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 200 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>
                  {t('Select Customer')}
                </Text>
                <SingleSelect
                  borderRadius={30}
                  placeholder='customer'
                  options={customers}
                  value={(event) => setCustomer(event.value)}
                />
              </FormGroup>
            </div>

            {/* From Datepicker */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 160 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('From')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setDate(data)}
                  deafultValue={date}
                />
              </FormGroup>
            </div>

            {/* To Datepicker */}
            <div style={{ width: size.width <= 576 ? '100%' : 160 }}>
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('To')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setToDate(data)}
                  deafultValue={toDate}
                />
              </FormGroup>
            </div>
          </div>
        </Container.Column>
        {loading && !data?.length && !serverError ? <Loader /> : null}
        {!loading && !data?.length && !serverError ? (
          <NoContent message={t('No products available.')} />
        ) : null}
        {!loading && !data?.length && serverError ? (
          <NetworkError message={t('Network Error!!!')} />
        ) : null}

        {!loading && data?.length && !serverError ? (
          <div className='w-100'>
            {/* Data table */}
            <Container.Column>
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                // searchable
                placeholder={t('Search Customer')}
                // search={handleSearch}
                // suggestion={handleSuggestion}
                searchLoading={searchLoading}
                clearSearch={() => fetchEmployee(1)}
              />
            </Container.Column>
          </div>
        ) : null}
      </Main>
    </div>
  );
};

export default ProductReturnAndReplacement;
