import { useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { GrayButton } from '../../components/button/Index';
import { Container } from '../../components/container/Index';
import { CustomerForm } from '../../components/form/CustomerForm';
import { Layout, Main } from '../../components/layout/Index';
import { Toastify } from '../../components/toastify/Toastify';
import { Requests } from '../../utils/Http/Index';

const Create = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [creating, setCreate] = useState(false);

  const handleCustomerCreate = async (data) => {
    try {
      setCreate(true);
      const response = await Requests.Customer.AddCustomer(data);
      if (response.status === 201) {
        Toastify.Success('Customer Created Successfully');
        history.push('/dashboard/customer');
      }
      setCreate(false);
    } catch (error) {
      setCreate(false);
      if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 422
      ) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Toastify.Error(Object.values(error.response.data.errors)[0][0]);
        } else {
          Toastify.Error(error.response.data.error);
        }
      } else {
        Toastify.Error('Network Error');
      }

      // if (error && error.response && error.response.status && error.response.status === 422) {
      //     Object.keys(error.response.data.errors).forEach(key => {
      //         setError(key, {
      //             type: "manual",
      //             message: error.response.data.errors[key][0],
      //         });
      //     });
      // } else {
      //     Toastify.Error(t("Network Error"))
      // }
    }
  };

  return (
    <>
      <Layout
        page={t('inventory / add customer')}
        message={t('Create Customer In your shop.')}
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/customer'>
              <GrayButton type='button'>
                <ArrowLeft size={15} />
                <span style={{ fontSize: 13 }}>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      <Main>
        <Container.Column>
          <CustomerForm
            errors={null}
            loading={creating}
            submit={handleCustomerCreate}
            create={true}
          />
        </Container.Column>
      </Main>
    </>
  );
};

export default Create;
