// Customer.js
import axiosInstance from './axiosInterceptor'; // Import the axios instance with interceptor
import { API } from '../Api';

// for all customer list
const AllCustomer = async (
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&from=${fromDate}&to=${toDate}`,
    config
  );
};

// for all customer list
const CustomerList = async (page, perpage, date = '') => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

// for all customer list
const CustomerDetailsByPhone = async (phone) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(`${API}/user-by-phone/${phone}`, config);
};

// for adding customer
const AddCustomer = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/customers`, data, config);
};

// for updating customer
const UpdateCustomer = async (data, uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/customers/${uid}`, data, config);
};

// for deleting customer
const DeleteCustomer = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.delete(
    `${API}/customers/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

// for showing single customer
const ShowCustomer = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const CustomerSearch = async (search) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}`,
    config
  );
};

// for showing single customer
const CustomerPurchaseHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers/${uid}/purchase-history?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};
// for showing single customer
const CustomerDueHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers/${uid}/due-history?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};

// for showing single customer
const CustomerPaymentDueHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/customers/${uid}/due-pay-history?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};

const Customer = {
  AllCustomer,
  CustomerPurchaseHistory,
  CustomerDueHistory,
  CustomerPaymentDueHistory,
  CustomerList,
  AddCustomer,
  UpdateCustomer,
  DeleteCustomer,
  ShowCustomer,
  CustomerSearch,
  CustomerDetailsByPhone,
};

export default Customer;
