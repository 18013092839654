// src/redux/store.js
import logoReducer from './slices/logoSlice';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    logo: logoReducer,
  },
});

export default store;
