import moment from 'moment';

export const englishToBengali = (english) => {
  localStorage.getItem('language');
  if (localStorage.getItem('language') === 'bn') {
    english = numberE2B(english);
  }
  return english;
};

export const numberE2B = (number) => {
  let bengaliNumber = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
  let englishNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  number = number + '';
  for (let i = 0; i < englishNumber?.length; i++) {
    number = number.replaceAll(englishNumber[i], bengaliNumber[i]);
  }
  return number;
};

export const formatAmount = (num) => {
  // Check if the number is in the thousands
  if (num >= 1000 && num < 1000000) {
    return (num / 1000).toFixed(2) + 'K'; // Convert to 'K' format
  }
  // Check if the number is in the millions
  else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(2) + 'M'; // Convert to 'M' format
  }
  // Check if the number is in the billions
  else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'B'; // Convert to 'B' format
  }
  // If the number is less than 1000, return it as is
  else {
    return num?.toString();
  }
};

// Phone number valid check
export const isValidPhone = () => {
  const regex = /^(?:\+88|88)?(01[3-9]\d{8})$/i;
  return regex;
};

// E-mail valid check
export const isValidEmail = () => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex;
};

// Date formate
export const dateFormate = (date) => {
  date = new Date(date);
  const cdate = date.toDateString();
  return cdate;
};

// Date Formate mm/dd/yyyy
export const dateFormat2 = (date) => {
  date = new Date(date);
  const newdate =
    date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  return newdate;
};

// Date Formate mm/dd/yyyy
export const dateFormatDMY = (date) => {
  date = new Date(date);
  const newdate =
    date.getDate() +
    '/' +
    Number(date.getMonth() + 1) +
    '/' +
    date.getFullYear();
  return newdate;
};

// Date Year Format yyyy/mm/dd
export const dateYearFormat = (date) => {
  date = new Date(date);
  const yearDate =
    date.getFullYear() +
    '-' +
    parseInt(date.getMonth() + 1) +
    '-' +
    date.getDate();
  return yearDate;
};

// Date formate with AM/PM
export const formatDateWithAMPM = (date) => {
  date = new Date(date);
  const cdate = date.toDateString();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = cdate + ', ' + hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const BatchIdGenerator = () => {
  const date = new Date();
  const string = localStorage.getItem('dokanname');
  const slicer =
    string.slice(0, 3) +
    '-' +
    date.getFullYear() +
    date.getMonth() +
    1 +
    date.getDate() +
    date.getMilliseconds() +
    '-' +
    Math.floor(Math.random() * 100 + 1);
  return slicer;
};

export const getCurrentDateTime = () => {
  const now = new Date();

  // Format the date and time using Moment.js
  const formattedDate = moment(now).format('DD MMM, YYYY');
  const formattedTime = moment(now).format('h.mm A');

  return `${formattedDate} at ${formattedTime}`;
};
