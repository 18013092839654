import React, { useCallback, useEffect, useState } from 'react';
import { Edit2, Eye, Plus, Printer, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BrandImage from '../../assets/logo.png';
import { NetworkError } from '../../components/501/NetworkError';
import {
  DangerButton,
  GrayButton,
  SuccessButton,
} from '../../components/button/Index';
import { Container } from '../../components/container/Index';
import { CustomerPayForm } from '../../components/form/CustomerPayForm';
import { Layout, Main } from '../../components/layout/Index';
import { DeleteModal } from '../../components/modal/DeleteModal';
import { PrimaryModal } from '../../components/modal/PrimaryModal';
import { DataTable } from '../../components/table/Index';
import { Text } from '../../components/text/Text';
import { Toastify } from '../../components/toastify/Toastify';
import { Requests } from '../../utils/Http/Index';
import {
  dateFormate,
  dateYearFormat,
  formatDateWithAMPM,
  getCurrentDateTime,
} from '../../utils/_heplers';

//for pdf
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { DatePicker } from '../../components/datePicker/Index';
import { size } from 'lodash';
import moment from 'moment';

const Index = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isDelete, setDelete] = useState({
    value: null,
    show: false,
    loading: false,
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [uid, setUid] = useState(null);
  const [searchLoading, setsearchLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [payModal, setPayModal] = useState({
    value: null,
    show: false,
    loading: false,
  });

  const handleGeneratePDF = () => {
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');

    const pdf = new jsPDF();
    const columns = [
      'Name',
      'Email',
      'Phone',
      'Total Purchase (Tk)',
      'Total Due (Tk)',
      'Last Payback',
    ];

    const imageX = pdf.internal.pageSize.width - 30;
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165;
    const textY = 18;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15);
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(12);
    pdf.text(textX, textY + 6, 'CUSTOMER DATA');

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10;
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns],
      body: data.map((row) => [
        row.name,
        row.email ?? 'N/A',
        row.phone ?? 'N/A',
        row.total_purchase ?? 0,
        row.total_due ?? 0,
        row.last_payback ? dateFormate(row.last_payback) : 'N/A',
      ]),
      styles: {
        font: 'RobotoCondensedBold',
        halign: 'center', // Align text to the center
        cellPadding: 2, // Adjust cell padding for better spacing
        lineColor: [0, 0, 0], // Set border color to black
        lineWidth: 0.5, // Set border width
      },
      headStyles: {
        fillColor: [22, 66, 60], // Set the header background color
        textColor: [255, 255, 255], // Set the header text color to white
        lineColor: [0, 0, 0], // Set header border color to black
        lineWidth: 0.2, // Set header border width
      },
      bodyStyles: {
        fillColor: [255, 255, 255], // Make the background white
        lineColor: [196, 218, 210], // Set body border color to black
        lineWidth: 0.2, // Set body border width
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255], // Ensure no stripes
      },
      margin: { top: 10 },
    });

    pdf.save(`customer-${getCurrentDateTime()}.pdf`);
  };

  const fetchCustomers = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const response = await Requests.Customer.AllCustomer(page, perPage);
        setData(response.data.data);
        setTotalRows(response.data.meta.total);
        setLoading(false);
        setServerError(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  const handlePageChange = (page) => fetchCustomers(page);

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await Requests.Customer.AllCustomer(page, perPage);
      if (response.status === 200) {
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  };

  useEffect(() => {
    fetchCustomers(1);
  }, [fetchCustomers]);

  // filter by start date
  const handleDateFilter = async (data) => {
    if (data !== null) {
      setLoading(true);
      try {
        const response = await Requests.Customer.AllCustomer(
          1,
          perPage,
          dateYearFormat(data)
        );
        if (response.status === 200) {
          setData(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    } else {
      fetchCustomers(1);
    }
  };

  const fetchUsersWithQuery = useCallback(
    async (fromDate, toDate) => {
      setLoading(true);
      const formattedDateFrom = fromDate
        ? moment(fromDate).format('YYYY-MM-DD')
        : '';
      const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      try {
        const response = await Requests.Customer.AllCustomer(
          totalRows,
          perPage,
          formattedDateFrom,
          formattedDateTo
        );
        setData(response.data.data);
        setTotalRows(response.data.total);
      } catch (error) {
        // Handle error
        console.error('Error fetching accounts with query:', error);
        // setError(true);
      } finally {
        setLoading(false);
      }
    },
    [fromDate, toDate, perPage]
  );

  useEffect(() => {
    if (fromDate && toDate) {
      // If date and toDate are set, fetch with query parameters
      fetchUsersWithQuery(fromDate, toDate);
    } else {
      // Otherwise, fetch without query parameters
      fetchCustomers();
    }
  }, [fetchCustomers, fetchUsersWithQuery]);

  const handleSuggestion = async (value) => {
    let data = {
      results: [],
      message: null,
    };
    const response = await Requests.Customer.CustomerSearch(value);
    if (response && response.data.data && response.data.data.length) {
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        data.results.push(element.name);
      }
    } else {
      data.message = 'No results found';
    }

    return data;
  };

  // Handle search
  const handleSearch = async (data) => {
    setsearchLoading(true);
    const response = await Requests.Customer.CustomerSearch(data);
    if (response.data) setData(response.data.data);
    setsearchLoading(false);
  };

  // Handle payment submission
  const handlePaymentSubmission = async (data) => {
    try {
      setPayModal({ ...payModal, loading: true });

      const FormData = {
        dokan_uid: localStorage.getItem('dokanuid'),
        pay_date: dateYearFormat(data.date),
        amount: data.amount,
        prev_due_consider: data.isConsider === true ? true : false,
        note: data.note,
      };

      const response = await Requests.Dues.PayDue(payModal.value.uid, FormData);
      if (response.data && response.status === 201) {
        Toastify.Success('Due Paid Successfully');
        setPayModal({ value: null, loading: false, show: false });
        fetchCustomers();
      }
    } catch (error) {
      if (error) {
        setPayModal({ ...payModal, loading: false });
        Toastify.Error('Network Error.');
      }
    }
  };

  // Handle delete
  const handleDelete = async () => {
    setDelete({ ...isDelete, loading: true });
    const res = await Requests.Customer.DeleteCustomer(uid);
    if (res.status === 200) {
      setDelete({ ...isDelete, loading: false, show: false });
      Toastify.Success(t('Customer deleted.'));
      fetchCustomers();
    } else {
      setDelete({ ...isDelete, loading: false, show: false });
      Toastify.Error(t("Customer can't deleted."));
    }
  };

  console.log(payModal);

  // Data columns
  const columns = [
    {
      name: `${t('Action')}`,
      grow: 0,
      minWidth: '300px',
      cell: (row) => (
        <div>
          <SuccessButton
            className='mr-1'
            type='button'
            onClick={() => setPayModal({ ...payModal, show: true, value: row })}
          >
            <Text className='mb-0 fs-12'>{t('PAY NOW')}</Text>
          </SuccessButton>

          <Link to={`/dashboard/customer/show/${row.uid}`}>
            <SuccessButton className='circle-btn mr-1'>
              <Eye size={16} />
            </SuccessButton>
          </Link>

          <Link to={`/dashboard/customer/edit/${row.uid}`}>
            <SuccessButton className='circle-btn mr-1'>
              <Edit2 size={16} />
            </SuccessButton>
          </Link>

          {/* <DangerButton
            className='circle-btn'
            onClick={() => {
              setUid(row.uid);
              setDelete({ value: row, show: true });
            }}
          >
            <Trash2 size={16} />
          </DangerButton> */}
        </div>
      ),
    },
    {
      name: `${t('Name')}`,
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: `${t('Phone')}`,
      selector: (row) => row.phone ?? 'N/A',
      sortable: true,
    },
    {
      name: `${t('Total Purchase (Tk)')}`,
      selector: (row) => row.total_purchase ?? 0,
      sortable: true,
    },
    {
      name: `${t('Total Due (Tk)')}`,
      selector: (row) => row.total_due ?? 0,
      sortable: true,
    },
    {
      name: `${t('Last Payback')}`,
      selector: (row) =>
        row.last_payback ? dateFormate(row.last_payback) : 'N/A',
      sortable: true,
    },
  ];

  return (
    <div>
      <Layout
        page={t('dashboard / customers')}
        message={t('Your Customer List')}
        container='container-fluid'
        button={
          <div>
            <Link to='/dashboard/customer/create'>
              <GrayButton>
                <Plus size={15} style={{ marginRight: 5 }} />
                <span style={{ fontSize: 13 }}>{t('ADD CUSTOMER')}</span>
              </GrayButton>
            </Link>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      />

      <Main>
        <Container.Column className='pr-4 mb-2'>
          <div className='d-sm-flex justify-content-between align-items-end pr-2'>
            {/* From Datepicker */}
            <div className='pr-sm-2 mb-2 mb-sm-0' style={{ width: '160px' }}>
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('From')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setFromDate(data)}
                  deafultValue={fromDate}
                />
              </FormGroup>
            </div>

            {/* To Datepicker */}
            <div className='pr-sm-2 mb-2 mb-sm-0' style={{ width: '160px' }}>
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('To')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setToDate(data)}
                  deafultValue={toDate}
                />
              </FormGroup>
            </div>

            {/* Search Field */}
            <div style={{ flexGrow: 1 }}>
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>
                  {t('Search')}
                </Text>
                <input
                  type='text'
                  className='form-control  w-25 rounded-pill'
                  placeholder={t('Search Customer')}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </FormGroup>
            </div>
          </div>
        </Container.Column>

        {serverError ? <NetworkError message='Network Error.' /> : null}

        {!serverError ? (
          <Container.Column>
            <DataTable
              columns={columns}
              data={data}
              loading={loading}
              totalRows={totalRows}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              noDataMessage='No customer available'
              placeholder={'Search Customer'}
              // search={handleSearch}
              // searchLoading={searchLoading}
              // suggestion={handleSuggestion}
              clearSearch={() => fetchCustomers(1)}
            />
          </Container.Column>
        ) : null}
      </Main>

      {/* Pay form modal */}
      <PrimaryModal
        show={payModal.show}
        onHide={() => setPayModal({ value: null, loading: false, show: false })}
        size='md'
        title={` Pay For ${payModal?.value?.name}`}
      >
        <CustomerPayForm
          value={payModal?.value}
          loading={payModal.loading}
          onSubmit={handlePaymentSubmission}
          onHide={() =>
            setPayModal({ value: null, loading: false, show: false })
          }
        />
      </PrimaryModal>

      {/* Delete confirmation modal */}
      <DeleteModal
        show={isDelete.show}
        loading={isDelete.loading}
        message={
          <Text className='fs-15 mb-0'>
            {t('Want to delete ')}
            {isDelete.value ? isDelete.value.email : null} ?
          </Text>
        }
        onHide={() => setDelete({ value: null, show: false, loading: false })}
        doDelete={handleDelete}
      />
    </div>
  );
};

export default Index;
