const getUser = () => {
  const existingUser = localStorage.getItem('dokanuid');
  if (existingUser) {
    return existingUser;
  } else {
    const newUser = 'user-' + localStorage.getItem('dokanuid');
    localStorage.setItem('dokanname', newUser);
    return newUser;
  }
};

const getDataKey = () => {
  const userId = getUser();
  return `dokan/${userId}`;
};

// push to local storage: a temporary place for database
const getDatabaseCart = () => {
  const dataKey = getDataKey();
  const data = localStorage.getItem(dataKey) || '{}';
  return JSON.parse(data);
};

const addToDatabaseCart = (key, count) => {
  const currentCart = getDatabaseCart();
  currentCart[key] = count;

  console.log('addToDatabaseCart', count);
  localStorage.setItem(getDataKey(), JSON.stringify(currentCart));
};

const removeFromDatabaseCart = (key) => {
  const currentCart = getDatabaseCart();
  delete currentCart[key];
  localStorage.setItem(getDataKey(), JSON.stringify(currentCart));
};

const processOrder = (cart) => {
  localStorage.removeItem(getDataKey());
};

export {
  addToDatabaseCart,
  getDatabaseCart,
  processOrder,
  removeFromDatabaseCart,
};

// polyfill to support older browser
const localStorage =
  window.localStorage ||
  (() => {
    let store = {};
    return {
      getItem(key) {
        return store[key];
      },
      setItem(key, value) {
        store[key] = value.toString();
      },
      clear() {
        store = {};
      },
    };
  })();

// const sessionStorage = window.sessionStorage || (() => {
//     let store = {}
//     return {
//         getItem(key) {
//             return store[key]
//         },
//         setItem(key, value) {
//             store[key] = value.toString()
//         },
//         clear() {
//             store = {}
//         }
//     };
// })()
