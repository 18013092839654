import React, { useCallback, useEffect, useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import {
  DangerButton,
  GrayButton,
  PrimaryButton,
} from '../../../components/button/Index';
import { Card } from '../../../components/card/Index';
import { Container } from '../../../components/container/Index';

import { Image, ImageCircle } from '../../../components/image/Index';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { ShortName } from '../../../components/shortName/Index';
import { Text } from '../../../components/text/Text';
import { dateFormat2, dateFormate } from '../../../utils/_heplers';
import { Requests } from '../../../utils/Http/Index';
import { DataTable } from '../../../components/table/Index';
import { FormGroup } from '../../../components/formGroup/FormGroup';
import { useForm } from 'react-hook-form';
import { DatePicker } from '../../../components/datePicker/Index';
import { payment } from '../../../utils/data';
import moment from 'moment';
import { Toastify } from '../../../components/toastify/Toastify';

const Return = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [returnQuantityList, setReturnQuantityList] = useState({});
  const [returnCalculationList, setReturnCalculationList] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [totalReturnAmount, setTotalReturnAmount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [date, setDate] = useState(new Date());
  const [paymentOption, setPaymentOption] = useState('cash');
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const fetchReturn = useCallback(async () => {
    try {
      const response = await Requests.POS.checkProductReturn(id);
      setLoading(false);
      setData(response.data.data);
      console.log(response.data.order_info);
      setOrderData(response.data.order_info);
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  }, [id]);

  useEffect(() => {
    fetchReturn();
  }, [fetchReturn]);

  // styles
  const styles = {
    name: {
      width: 80,
    },
    td: {
      width: 100,
    },
  };

  const handlePageChange = (page) => fetchReturn(page);

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await Requests.POS.checkProductReturn(id, page, perPage);

      if (response.status === 200) {
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  };
  // Function to handle return quantity change
  const handleReturnQuantityChange = (event, row) => {
    const value = Math.max(0, Math.min(row.quantity, event.target.value)); // Ensure valid value
    const updatedReturnQuantityList = {
      ...returnQuantityList,
      [row.uid]: value,
    };
    setReturnQuantityList(updatedReturnQuantityList);

    // Calculate the subtotal for each product
    const updatedReturnCalculationList = {
      ...returnCalculationList,
      [row.uid]: row.unit_price * value,
    };
    setReturnCalculationList(updatedReturnCalculationList);

    // Calculate total items and return amount
    const totalReturnQuantity = Object.values(updatedReturnQuantityList).reduce(
      (acc, curr) => acc + parseInt(curr || 0),
      0
    );
    const totalReturnAmount = Object.values(
      updatedReturnCalculationList
    ).reduce((acc, curr) => acc + parseFloat(curr || 0), 0);

    setTotalItems(totalReturnQuantity);
    setTotalReturnAmount(totalReturnAmount);
  };

  const columns = [
    {
      name: `${t('Product Name')}`,
      selector: (row) => row.product_name || 'N/A',
      sortable: true,
    },
    {
      name: `${t('Product Code')}`,
      selector: (row) => row.product_code || 'N/A',
      sortable: true,
    },

    {
      name: `${t('Sale Quantity')}`,
      selector: (row) => row.quantity || 0,
      sortable: true,
    },
    {
      name: `${t('Return Quantity')}`,
      selector: (row) => (
        <div className='d-flex justify-content-center align-items-center'>
          <FormGroup className='m-0'>
            <input
              type='number'
              value={returnQuantityList[row.uid] || 0}
              className='form-control shadow-none text-center' // Added text-center for centering text
              min={0}
              max={row.quantity}
              onChange={(event) => handleReturnQuantityChange(event, row)}
            />
          </FormGroup>
        </div>
      ),
      sortable: true,
    },

    {
      name: `${t('Price')}`,
      selector: (row) => row.unit_price || 'N/A',
    },
    {
      name: `${t('Sub Total')}`,
      selector: (row) =>
        row.unit_price * row.quantity -
        row.unit_price * (returnQuantityList[row.uid] || 0),
    },
  ];

  const onSubmit = async (formData) => {
    if (!date) {
      setError('date', {
        type: 'manual',
        message: 'Date is required',
      });
    }
    if (!paymentOption) {
      setError('payment_method', {
        type: 'manual',
        message: 'Payment Method is required',
      });
    }

    const busket = data.map((item) => ({
      items_uid: item.uid,
      unit_price: item.unit_price,
      quantity: returnQuantityList[item.uid] || 0,
      code_uid: item.code_uid,
    }));

    const newData = {
      ...formData,
      return_replace_date: moment(date), // Replace with actual date logic
      customer_uid: orderData.customer.customer_uid,
      stock_in: formData.stock_in ? 1 : 0,
      add_prev_due: formData.add_prev_due ? 1 : 0,
      order_uid: orderData.uid,
      dokan_uid: localStorage.getItem('dokanuid'),
      return_quantity: totalItems,
      payment_method: paymentOption,
      return_amount: totalReturnAmount,
      user_uid: orderData.customer.user_uid,
      busket,
    };

    try {
      setLoading(true);
      const response = await Requests.Inventory.Product.DokanProductReturn(
        newData
      );
      if (response.status === 201) {
        setLoading(false);
        Toastify.Success('Product Returned Successfully!');

        history.push('/dashboard/orders');
      }
    } catch (error) {
      setLoading(false);
      setServerError(true);
      Toastify.Error('Product Could Not be Returned');
    }
  };

  return (
    <div>
      <Layout
        page={t('dashboard / Order / Return')}
        message={t('Sale Return')}
        container='container-fluid'
        button={
          <div>
            <Link to='/dashboard/orders'>
              <GrayButton type='button'>
                <ChevronLeft size={15} className='mr-1' />
                <span>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      <Main>
        {loading && !Object.keys(orderData)?.length && !serverError ? (
          <Loader />
        ) : null}
        {!loading && !Object.keys(orderData)?.length && serverError ? (
          <NetworkError message='Network Error!' />
        ) : null}
        {!loading && !Object.keys(orderData)?.length && !serverError ? (
          <NoContent message='No Return Order available.' />
        ) : null}

        <Container.Column>
          {/* Customer image and short name */}
          <div className='d-lg-flex'>
            {orderData ? (
              <div>
                <ImageCircle
                  src={orderData?.customer?.image}
                  alt=''
                  x={70}
                  y={70}
                />
              </div>
            ) : (
              <div style={orderData?.customer?.name}>
                <ShortName
                  x={70}
                  y={70}
                  size={35}
                  name={orderData?.customer?.name}
                />
              </div>
            )}

            {/* Personal info */}
            <div className='ml-lg-4 mt-4 mt-lg-0'>
              <Text className='text-capitalize fs-17 font-weight-bold mb-0'>
                {orderData?.customer?.name}.
              </Text>
              <table className='table table-sm table-borderless mb-0'>
                <tbody>
                  <tr>
                    <td className='pl-0' style={styles.td}>
                      <Text className='text-capitalized fs-13 mb-0'>
                        E-mail
                      </Text>
                    </td>
                    <td>
                      <Text className='text-lowercase fs-13 mb-0'>
                        :{' '}
                        {orderData?.customer?.email ? (
                          <span className='text-uppercase'>
                            {orderData?.customer?.email}
                          </span>
                        ) : (
                          <span className='text-uppercase'>N/A</span>
                        )}
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td className='pl-0' style={styles.td}>
                      <Text className='text-capitalized fs-13 mb-0'>Phone</Text>
                    </td>
                    <td>
                      <Text className='text-capitalized fs-13 mb-0'>
                        : {orderData?.customer?.phone ?? 'N/A'}
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td className='pl-0' style={styles.td}>
                      <Text className='text-capitalized fs-13 mb-0'>
                        Street Address
                      </Text>
                    </td>
                    <td>
                      <Text className='text-capitalized fs-13 mb-0'>
                        : {orderData?.customer?.address.district ?? 'N/A'}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Container.Column>

        {/* Total purchase & due */}
        <Container.Column>
          <Container.Row className='m-0 mt-3'>
            <Container.Column className='col-sm-6 col-md-3 col-xl-2 text-center p-1'>
              <Card.Simple>
                <Card.Body className='px-0'>
                  <Text className='fs-16 font-weight-bold mb-0'>
                    {' '}
                    {orderData && orderData.paid ? orderData.paid : 0} TK
                  </Text>
                  <Text className='fs-16 mb-0 text-muted'> PAID</Text>
                </Card.Body>
              </Card.Simple>
            </Container.Column>

            <Container.Column className='col-sm-6 col-md-3 col-xl-2 text-center p-1'>
              <Card.Simple>
                <Card.Body className='px-0'>
                  <Text className='fs-16 font-weight-bold mb-0'>
                    {' '}
                    {orderData && orderData.due ? orderData.due : 0} TK
                  </Text>
                  <Text className='fs-16 mb-0 text-muted'> DUE </Text>
                </Card.Body>
              </Card.Simple>
            </Container.Column>
            <Container.Column className='col-sm-6 col-md-3 col-xl-2 text-center p-1'>
              <Card.Simple>
                <Card.Body className='px-0'>
                  <Text className='fs-16 font-weight-bold mb-0'>
                    {' '}
                    {orderData && orderData.grand_total
                      ? orderData.grand_total
                      : 0}{' '}
                    TK
                  </Text>
                  <Text className='fs-16 mb-0 text-muted'> GRAND TOTAL </Text>
                </Card.Body>
              </Card.Simple>
            </Container.Column>
          </Container.Row>
        </Container.Column>

        {/* History tabs */}
        {Object.keys(data)?.length ? (
          <Container.Column className='mt-2 mt-lg-4'>
            <Text>Product Return Available List</Text>
            <Container.Column>
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                noDataMessage='No Return history available'
                // searchable
                // placeholder={'Search'}
                // search={handleSearch}
                // searchLoading={searchLoading}
                // suggestion={handleSuggestion}
                // clearSearch={() => fetchPurchaseHistory(1)}
              />
            </Container.Column>
          </Container.Column>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container.Row>
            {/* Return Date */}
            <Container.Column className='col-lg-6'>
              <FormGroup>
                {errors.return_replace_date &&
                errors.return_replace_date.message ? (
                  <Text className='text-danger fs-13 mb-1'>
                    {errors.return_replace_date &&
                      errors.return_replace_date.message}
                  </Text>
                ) : (
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Return Date')}
                  </Text>
                )}

                <DatePicker
                  selected={(data) => {
                    setDate(data);
                    clearErrors();
                  }}
                  deafultValue={date}
                />
              </FormGroup>
            </Container.Column>

            {/* Return Amount Type */}
            <Container.Column className='col-lg-6'>
              <FormGroup>
                {errors.payment_method && errors.payment_method.message ? (
                  <Text className='text-danger fs-13 mb-1'>
                    {errors.payment_method && errors.payment_method.message}
                  </Text>
                ) : (
                  <Text className='fs-13 mb-1'>{t('Return Amount Type')}</Text>
                )}

                <select
                  className='form-control shadow-none'
                  name='paymentoption'
                  onChange={(event) => setPaymentOption(event.target.value)}
                  required
                  defaultValue={paymentOption}
                >
                  {payment &&
                    payment.map((item, i) => (
                      <option key={i} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </select>
              </FormGroup>
            </Container.Column>

            {/* reason */}
            <Container.Column className='col-lg-6'>
              <FormGroup>
                {errors.return_reason && errors.return_reason.message ? (
                  <Text className='text-danger fs-13 mb-1'>
                    {errors.return_reason && errors.return_reason.message}
                  </Text>
                ) : (
                  <Text className='fs-13 mb-0'>
                    {t('Return Reason')}{' '}
                    <span className='text-muted'> (optional)</span>{' '}
                  </Text>
                )}

                <textarea
                  rows='3'
                  className={
                    errors.return_reason
                      ? 'form-control shadow-none error'
                      : 'form-control shadow-none'
                  }
                  placeholder={t('Enter Return Reason here')}
                  {...register('return_reason')}
                  defaultValue={null}
                />
              </FormGroup>
            </Container.Column>

            {/* Note */}
            <Container.Column className='col-lg-6'>
              <FormGroup>
                {errors.note && errors.note.message ? (
                  <Text className='text-danger fs-13 mb-1'>
                    {errors.note && errors.note.message}
                  </Text>
                ) : (
                  <Text className='fs-13 mb-0'>
                    {t('Note')} <span className='text-muted'> (optional)</span>{' '}
                  </Text>
                )}

                <textarea
                  rows='3'
                  className={
                    errors.note
                      ? 'form-control shadow-none error'
                      : 'form-control shadow-none'
                  }
                  placeholder={t('Enter your note here')}
                  {...register('note')}
                  defaultValue={null}
                />
              </FormGroup>
            </Container.Column>

            <Container.Column className='d-flex col-lg-6'>
              <Form.Check
                custom
                type='checkbox'
                name='stock_in'
                id={`stockCheck`}
                style={{ fontSize: 14 }}
                {...register('stock_in')}
                defaultChecked={true}
              />
              <Text>Do you want to stock in this product ?</Text>
            </Container.Column>
            <Container.Column className='d-flex col-lg-6'>
              <Form.Check
                custom
                type='checkbox'
                name='add_prev_due'
                id={`add_prev_due`}
                style={{ fontSize: 14 }}
                {...register('add_prev_due')}
                defaultChecked={true}
              />
              <Text>Do you want add in previous due ?</Text>
            </Container.Column>
          </Container.Row>
          <Container.Column>
            <Container.Row className='ml-0 d-flex align-items-center justify-content-end mt-4  mr-4'>
              <div className='d-flex align-items-center justify-content-end  mr-4'>
                {' '}
                <Text className='text-center mt-3 px-4'>Total Items</Text>
                <GrayButton type='button' className='px-5 mt-md-0 mt-2'>
                  <Text className='fs-15 mb-0'>{totalItems}</Text>
                </GrayButton>
              </div>
              <div className='d-flex align-items-center justify-content-end  mr-4'>
                {' '}
                <Text className='text-center mt-3 px-4'>
                  Total Return Amount
                </Text>
                <GrayButton type='button' className='px-5 mt-md-0 mt-2'>
                  {/*Note: in this text show return value and dont need click functionaliry, show the value based on return quantity increase or decrease */}
                  <Text className='fs-15 mb-0'>{totalReturnAmount}</Text>
                </GrayButton>
              </div>
            </Container.Row>
          </Container.Column>
          <Container.Column>
            <Container.Row className='ml-0 d-flex align-items-center justify-content-end mt-4  mr-4'>
              <div className='d-flex align-items-center justify-content-end  mr-4'>
                <DangerButton
                  onClick={() => {
                    history.push('/dashboard/orders');
                  }}
                  type='button'
                  className='px-5 mt-md-0 mt-2'
                >
                  <Text className='fs-15 mb-0'>Cancel</Text>
                </DangerButton>
              </div>
              <div className='d-flex align-items-center justify-content-end  mr-4'>
                <PrimaryButton type='submit' className='px-5 mt-md-0 mt-2'>
                  <Text className='fs-15 mb-0'>Confirm Return</Text>
                </PrimaryButton>
              </div>
            </Container.Row>
          </Container.Column>
        </form>
      </Main>
    </div>
  );
};

export default Return;
