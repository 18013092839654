import React, { useState, useCallback, useEffect } from 'react';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { GrayButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { SupplierForm } from '../../../components/form/SupplierForm';
import { Layout, Main } from '../../../components/layout/Index';
import { Toastify } from '../../../components/toastify/Toastify';
import { Requests } from '../../../utils/Http/Index';
import { SupplierBillForm } from '../../../components/form/SupplierBillForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SupplierBillFormWithId } from '../../../components/form/SupplierBillFormWithId';

const Index = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [creatingsupplier, setCreatingSupplier] = useState(false);
  const [error, setError] = useState();
  const [pageloading, setPageLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const { id } = useParams(); // Retrieve the id parameter (it may or may not be present)
  const [data, setData] = useState(null);

  // Fetch suppliers for the dropdown
  const fetchSuppliers = useCallback(async () => {
    setPageLoading(true);
    try {
      const response = await Requests.Inventory.Supplier.DokanSupplierList();

      const suppliers = response.data.data.map((supplier) => {
        return { label: supplier.name, value: supplier.uid };
      });
      setPageError(false);
      setSuppliers(suppliers);
      setPageLoading(false);
    } catch (error) {
      setPageError(true);
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  // Fetch the specific supplier bill data if id is present
  const fetchData = useCallback(async () => {
    if (id) {
      try {
        const response = await Requests.Inventory.Supplier.DokanSupplierShow(
          id
        );
        if (response && response.status === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          setError(true);
        }
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) fetchData();
  }, [fetchData, id]);

  // Handle form submission
  const handleCreateSupplier = async (data) => {
    try {
      setCreatingSupplier(true);
      const newdata = {
        ...data,
        dokan_uid: localStorage.getItem('dokanuid'),
      };

      const res = await Requests.Inventory.Supplier.DokanSupplierBillAdd(
        newdata
      );
      if (res.status === 201) {
        Toastify.Success(t('Supplier Bill Created Successfully'));
        if (id) {
          history.push('/dashboard/inventory/supplier/list');
        } else {
          history.push('/dashboard/inventory/supplier-bill/list');
        }
      }

      setCreatingSupplier(false);
    } catch (error) {
      if (error?.response?.data?.errors) {
        Toastify.Error(Object.values(error.response.data.errors)[0][0]);
      } else {
        Toastify.Error('Some fields are missing.');
      }
      setCreatingSupplier(false);
    }
  };

  return (
    <div>
      <Layout
        page='inventory / add supplier bill'
        message='Add new Supplier Bill details for your shop.'
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/inventory/supplier-bill/list'>
              <GrayButton type='button'>
                <ArrowLeft size={15} style={{ marginRight: 5 }} />
                <span style={{ fontSize: 13 }}>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      <Main>
        {id ? (
          <>
            {' '}
            <Container.Column>
              <SupplierBillFormWithId
                errors={error}
                suppliers={suppliers}
                loading={creatingsupplier}
                submit={handleCreateSupplier}
                supplier={data} // Data only exists if id is present
              />
            </Container.Column>
          </>
        ) : (
          <>
            {' '}
            <Container.Column>
              <SupplierBillForm
                errors={error}
                suppliers={suppliers}
                loading={creatingsupplier}
                submit={handleCreateSupplier}
              />
            </Container.Column>
          </>
        )}
      </Main>
    </div>
  );
};

export default Index;
