import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import { Menu, Minimize, PlusSquare } from 'react-feather';
import { Image } from '../image/Index';
import Dropdown from './Dropdown';
import { Images } from '../../utils/Images';
import LanguageSelector from '../../components/languageSelector/Index';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Requests } from '../../utils/Http/Index';
import {
  PrimaryButton,
  DangerButton,
  SuccessButton,
  GreenButton,
  RedButton,
} from '../button/Index';
import { Text } from '../text/Text';
import { useSelector } from 'react-redux';
import { FormGroup } from '../formGroup/FormGroup';
import { SingleSelect } from '../select/Index';
import { useWindowSize } from '../window/windowSize';

const Index = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(null);
  const logo = useSelector((state) => state.logo.logo);
  const size = useWindowSize();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // Check if dokanuid is available
  const dokanuid = localStorage.getItem('dokanuid');
  const showPOSOptions = Boolean(dokanuid); // Show POS options only if dokanuid exists

  const fetchData = useCallback(async () => {
    if (history.location.pathname !== '/shop' && showPOSOptions) {
      try {
        const response = await Requests.Settings.DokanSettingInformation();
        if (response.status === 200) setData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  }, [history, showPOSOptions]);

  useEffect(() => {
    if (showPOSOptions) {
      fetchData();
    }
  }, [fetchData, showPOSOptions]);

  const handleProductView = (data) => {
    history.push(`/dashboard/inventory/product/show/${data}`);
  };

  const fetchProducts = useCallback(async () => {
    if (!showPOSOptions) return;

    setLoading(true);
    setError(false);
    try {
      const response = await Requests.Inventory.Product.DokanProductList(0, 0);
      const products = response.data.data.map((product) => {
        return { label: product.name, value: product.uid };
      });
      setProducts(products);
      setLoading(false);
      setError(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  }, [showPOSOptions]);

  useEffect(() => {
    if (showPOSOptions) {
      fetchProducts();
    }
  }, [fetchProducts, showPOSOptions]);

  const dokantitle = data ? data?.dokan?.title : '';
  const LogoSrc = logo || (data ? data?.dokan?.logo : Images.DefaultLogo);

  return (
    <div className='navbar-container'>
      <div
        className={`d-flex align-items-center ${
          showPOSOptions ? 'justify-content-center' : 'justify-content-end'
        } `}
      >
        {showPOSOptions && (
          <div>
            <Image src={LogoSrc} alt='Company logo' x={50} y={50} />
          </div>
        )}
        <div className='d-flex text-white mt-3 px-4 align-items-center justify-content-center'>
          <Text>{dokantitle && dokantitle}</Text>
        </div>
        {showPOSOptions && (
          <div className='d-flex align-items-center ml-auto'>
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 200 }}
            >
              <FormGroup className='mb-0'>
                <SingleSelect
                  placeholder='Product'
                  options={products}
                  value={(event) => {
                    handleProductView(event.value);
                  }}
                />
              </FormGroup>
            </div>
            <Link to='/dashboard/pos/point-of-sell'>
              <GreenButton type='button' className='mr-2 pos-button'>
                <Text className='mb-0 text-white fs-12 d-flex justify-content-center align-items-center'>
                  <Minimize size={12} />
                  <span className='ml-1'>POS</span>
                </Text>
              </GreenButton>
            </Link>
            <Link to='/dashboard/accounting/add-expense'>
              <RedButton type='button' className='mr-2 add-expenses-button'>
                <Text className='mb-0 fs-12 d-flex justify-content-center align-items-center'>
                  <PlusSquare size={12} />
                  <span className='ml-1'>ADD EXPENSES</span>
                </Text>
              </RedButton>
            </Link>
          </div>
        )}
        <div>
          <Dropdown />
        </div>
        {props.menu && (
          <div className='d-lg-none pl-2'>
            <button
              type='button'
              className='btn shadow-none rounded-circle'
              onClick={props.drawer}
            >
              <Menu size={24} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
