import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text } from '../text/Text';
import { Toastify } from '../toastify/Toastify';
import { FormGroup } from '../formGroup/FormGroup';
import { PrimaryButton } from '../button/Index';
import { Requests } from '../../utils/Http/Index';

export const Location = (props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props]);

  // handle submit
  const onSubmit = async (data) => {
    try {
      const formData = {
        street_address: data.streetAddress,
        post_office: data.postOffice,
        zip_code: data.zipPostCode,
        upazilla: data.upazila,
        district: data.district,
        state: data.division,
      };

      clearErrors();
      setUpdating(true);

      const response = await Requests.Settings.DokanLocationUpdate(formData);
      if (response && response.status === 200) {
        Toastify.Success('Saved Your location settings.');
      }
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      if (error?.response?.status === 422) {
        Object.keys(error.response.data.errors).forEach((key) => {
          setError(key, {
            type: 'manual',
            message: error.response.data.errors[key][0],
          });
        });
      } else {
        Toastify.Error('Network error.');
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Street Address */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.streetAddress?.message || t('Street address')}
            </Text>
            <input
              type='text'
              className={
                errors.streetAddress
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('Street address')}
              defaultValue={props.location?.setting?.street_address || ''}
              {...register('streetAddress', {
                required: t('Street address is required'),
              })}
            />
          </FormGroup>

          {/* ZIP/Post code */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.zipPostCode?.message || t('ZIP / Post code')}
            </Text>
            <input
              type='text'
              className={
                errors.zipPostCode
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('ZIP / Post code')}
              defaultValue={props.location?.setting?.zip_code || ''}
              {...register('zipPostCode', {
                required: t('ZIP / Post code is required'),
              })}
            />
          </FormGroup>

          {/* Post Office */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.postOffice?.message || t('Post office')}
            </Text>
            <input
              type='text'
              className={
                errors.postOffice
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('Post office')}
              defaultValue={props.location?.setting?.post_office || ''}
              {...register('postOffice', {
                required: t('Post office is required'),
              })}
            />
          </FormGroup>

          {/* Upazila */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.upazila?.message || t('Upazila')}
            </Text>
            <input
              type='text'
              className={
                errors.upazila
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('Upazila')}
              defaultValue={props.location?.setting?.upazilla || ''}
              {...register('upazila', {
                required: t('Upazila is required'),
              })}
            />
          </FormGroup>

          {/* District / City */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.district?.message || t('District / City')}
            </Text>
            <input
              type='text'
              className={
                errors.district
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('District / City')}
              defaultValue={props.location?.setting?.district || ''}
              {...register('district', {
                required: t('District / City is required'),
              })}
            />
          </FormGroup>

          {/* Division / State */}
          <FormGroup>
            <Text className='text-capitalize fs-13 mb-1'>
              {errors.division?.message || t('Division / State')}
            </Text>
            <input
              type='text'
              className={
                errors.division
                  ? 'form-control shadow-none error'
                  : 'form-control shadow-none'
              }
              placeholder={t('Division / State')}
              defaultValue={props.location?.setting?.state || ''}
              {...register('division', {
                required: t('Division / State is required'),
              })}
            />
          </FormGroup>

          {/* Submit Button */}
          <div className='text-right'>
            <PrimaryButton type='submit' className='px-4' disabled={isUpdating}>
              {isUpdating ? t('Saving ...') : t('Save Changes')}
            </PrimaryButton>
          </div>
        </form>
      )}
    </div>
  );
};
