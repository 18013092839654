import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFViewer = ({ pdfUrl }) => {
  return pdfUrl ? (
    <div style={{ height: '80vh' }}>
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js'>
        <Viewer fileUrl={pdfUrl} />
      </Worker>
    </div>
  ) : (
    <p>Loading PDF...</p>
  );
};

export default PDFViewer;
