// src/components/BestSellingProducts.js
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { dateFormate } from '../../utils/_heplers';

// Define table columns for react-data-table-component
const columns = [
  {
    name: 'Invoice',
    selector: (row) => row.invoice_number,
    sortable: true,
  },

  {
    name: 'Customer Name',
    selector: (row) => row.customer_name,
    sortable: true,
  },
  {
    name: 'Phone',
    selector: (row) => row.customer_phone,
    sortable: true,
  },
  {
    name: 'Purchase Date',
    selector: (row) => dateFormate(row.purchase_date),
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: 'Total Amount',
    selector: (row) => row.total_amount,
    sortable: true,
  },

  //   {
  //     name: 'Actions',
  //     cell: (row) => (
  //       <div>
  //         <button className='btn btn-outline-primary btn-sm'>
  //           <i className='fas fa-eye'></i>
  //         </button>{' '}
  //         <button className='btn btn-outline-success btn-sm'>
  //           <i className='fas fa-edit'></i>
  //         </button>{' '}
  //         <button className='btn btn-outline-danger btn-sm'>
  //           <i className='fas fa-trash'></i>
  //         </button>
  //       </div>
  //     ),
  //   },
];

const BestSellingProducts = ({ dashBoardData }) => {
  return (
    <div
      className='card'
      style={{
        background: 'linear-gradient(45deg, #071952, #46458C)', // Add gradient background
        // padding: '10px 0px',
        // borderRadius: '10px',
      }}
    >
      <div className='card-header text-white'>
        <h4>Latest Orders</h4>
      </div>
      <div className=''>
        {/* <div className='row my-2'>
          <div className='col-md-3 '>
            <label htmlFor='showBy'>Show By</label>
            <select
              id='showBy'
              className='form-select'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option>None</option>
              <option>Top Rated</option>
              <option>Most Sold</option>
            </select>
          </div>
          <div className='col-md-3'>
            <label htmlFor='categoryBy'>Category By</label>
            <select
              id='categoryBy'
              className='form-select'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option>None</option>
              <option>Women</option>
              <option>Men</option>
            </select>
          </div>
        </div> */}

        {/* DataTable Component */}
        <DataTable
          columns={columns}
          paginationServer={false}
          data={dashBoardData.data}
          pagination={false}
          paginationComponentOptions={{ noRowsPerPage: true }}
          customStyles={{
            headCells: {
              style: {
                backgroundColor: '#071952',
                color: '#F9F5F6',
                fontWeight: 'bold',
              },
            },
            rows: {
              style: {
                backgroundColor: '#46458C',
                color: '#F9F5F6',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BestSellingProducts;
