import React from 'react';
import { formatAmount } from '../../utils/_heplers';

const DashboardCards = ({ dashBoardData }) => {
  return (
    <div className='row g-3 my-5'>
      {/* Left Column - 8 Columns */}
      <div className='col-md-6'>
        <div className='' style={{ color: '#664343' }}>
          {' '}
          <h4>Total Summary</h4>
        </div>
        <div className='row'>
          <div className='col-md-4 mb-3 '>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #401F71, #824D74)', // Gradient for primary card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Cash</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(dashBoardData?.dashboard_info?.total_cash)}
                    </h4>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='currentColor'
                    class='bi bi-bank'
                    viewBox='0 0 16 16'
                  >
                    <path d='m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z' />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Card 7 */}
          <div className='col-md-4 mb-3 '>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #254336, #B7B597)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Withdraw</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_withdraw
                      )}
                    </h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-wallet'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 8*/}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #3C5B6F, #948979)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Expense</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_expense
                      )}
                    </h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-arrow-up-left-square'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm10.096 8.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 1 */}

          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #433878, #7E60BF)', // Gradient for success card
              }}
            >
              <div className='card-body '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Customers</h5>
                    <h4>{dashBoardData?.dashboard_info?.total_customer}</h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-people'
                      viewBox='0 0 16 16'
                    >
                      <path d='M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 2 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #433878, #7E60BF)', // Gradient for success card
              }}
            >
              <div className='card-body '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Orders</h5>
                    <h4>{dashBoardData?.dashboard_info?.total_order}</h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-file-earmark-text'
                      viewBox='0 0 16 16'
                    >
                      <path d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5' />
                      <path d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #708871, #BEC6A0)', // Gradient for warning card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Product Sell</h5>
                    <h4>{dashBoardData?.dashboard_info?.total_product_sell}</h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-box-fill'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #921A40, #C75B7A)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Dues</h5>
                    <h4>
                      {' '}
                      ৳ {formatAmount(dashBoardData?.dashboard_info?.total_due)}
                    </h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-cash'
                      viewBox='0 0 16 16'
                    >
                      <path d='M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4' />
                      <path d='M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 5 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #468585, #50B498)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Due Paid</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_due_paid
                      )}
                    </h4>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-arrow-down-right-square'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 3.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 6 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #007bff, #0056b3)', // Gradient for primary card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Sales</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(dashBoardData?.dashboard_info?.total_sell)}
                    </h4>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='currentColor'
                    class='bi bi-currency-exchange'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z' />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Card 9 */}
        </div>
      </div>

      {/* Right Column - Full Height Card */}
      <div className='col-md-6'>
        <div className='' style={{ color: '#664343' }}>
          {' '}
          <h4>Today Summary</h4>
        </div>
        <div
          className='card text-white py-4  d-flex flex-column justify-content-between'
          style={{
            background: 'linear-gradient(45deg, #A79277, #D1BB9E)', // Custom gradient for this card
          }}
        >
          <div className='card-body '>
            <div className='row mt-3'>
              <div className='col-md-4'>
                <div className='d-flex justify-content-start align-items-center'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-file-earmark-text'
                      viewBox='0 0 16 16'
                    >
                      <path d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5' />
                      <path d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Orders</h5>
                    <h4>{dashBoardData?.dashboard_info?.today_total_order}</h4>
                  </div>
                </div>
              </div>
              <div className='col-md-8'>
                {' '}
                <div className='d-flex justify-content-start align-items-center '>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-box-fill'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z'
                      />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Product Sells</h5>
                    <h4>
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_product_sell
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-3 '>
              <div className='col-md-4'>
                <div className='d-flex justify-content-start align-items-center'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-cash'
                      viewBox='0 0 16 16'
                    >
                      <path d='M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4' />
                      <path d='M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z' />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Due</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_due
                      )}
                    </h4>
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                {' '}
                <div className='d-flex justify-content-start align-items-center '>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-arrow-down-right-square'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 3.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z'
                      />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Due Paid</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_due_paid
                      )}
                    </h4>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                {' '}
                <div className='d-flex justify-content-start align-items-center '>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-currency-exchange'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z' />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Sells</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_sell
                      )}
                    </h4>
                  </div>
                </div>
              </div>
              <div className='col-md-4 mt-3 '>
                <div className='d-flex justify-content-start align-items-center'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-wallet'
                      viewBox='0 0 16 16'
                    >
                      <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1' />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Withdraw</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_withdraw
                      )}
                    </h4>
                  </div>
                </div>
              </div>

              <div className='col-md-4 mt-3'>
                {' '}
                <div className='d-flex justify-content-start align-items-center '>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-arrow-up-left-square'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm10.096 8.803a.5.5 0 1 0 .707-.707L6.707 6h2.768a.5.5 0 1 0 0-1H5.5a.5.5 0 0 0-.5.5v3.975a.5.5 0 0 0 1 0V6.707z'
                      />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Expense</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_expense
                      )}
                    </h4>
                  </div>
                </div>
              </div>
              <div className='col-md-4 mt-3'>
                {' '}
                <div className='d-flex justify-content-start align-items-center '>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-bank'
                      viewBox='0 0 16 16'
                    >
                      <path d='m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z' />
                    </svg>
                  </div>
                  <div className='px-4'>
                    <h5> Cash</h5>
                    <h4>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.today_total_cash
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
