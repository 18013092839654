import React from 'react';
import { Printer, X } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DangerButton, GrayButton, PrimaryButton } from '../button/Index';
import { Text } from '../text/Text';

export const PrimaryModal = (props) => {
  const { t } = useTranslation();
  const {
    title,
    show,
    size,
    onHide,
    showDownload = false,
    handleGeneratePDF,
  } = props;

  return (
    <Modal show={show} size={size} centered onHide={onHide}>
      <Modal.Header className='border-0 p-4'>
        <div className='d-flex w-100'>
          <div>
            {!showDownload && (
              <Text className='fs-18 mt-2 mb-0'>{t(title)}</Text>
            )}
            {showDownload && (
              <div className='ml-auto'>
                <PrimaryButton
                  className='ml-2 mt-2 mt-sm-0'
                  onClick={handleGeneratePDF}
                >
                  <Printer size={15} style={{ marginRight: 5 }} />
                  <span style={{ fontSize: 14 }}>{t('PRINT')}</span>
                </PrimaryButton>
              </div>
            )}
          </div>
          <div className='ml-auto'>
            <DangerButton
              type='button'
              onClick={onHide}
              className='danger-delete-circle'
            >
              <X size={16} />
            </DangerButton>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className='px-4 pb-4 pt-0'>{props.children}</Modal.Body>
    </Modal>
  );
};
