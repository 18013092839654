import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { NetworkError } from '../../components/501/NetworkError';
import { DatePicker } from '../../components/datePicker/Index';
import { Layout, Main } from '../../components/layout/Index';
import { Container } from '../../components/container/Index';
import { SingleSelect } from '../../components/select/Index';
import { NoContent } from '../../components/204/NoContent';
import { DataTable } from '../../components/table/Index';
import { Loader } from '../../components/loading/Index';
import { Card } from '../../components/card/Index';
import { Text } from '../../components/text/Text';
import { Requests } from '../../utils/Http/Index';
import axiosInstance from '../../utils/Http/axiosInterceptor';
import { GrayButton } from '../../components/button/Index';
import { Printer } from 'react-feather';
import {
  dateFormat2,
  dateFormate,
  formatDateWithAMPM,
  getCurrentDateTime,
} from '../../utils/_heplers';
import jsPDF from 'jspdf';
import moment from 'moment';

const PurchaseReport = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [fromdate, setFromdate] = useState(new Date());
  const [customers, setCustomer] = useState(null);
  const [singleCustomer, setSingleCustomer] = useState({});
  const [error, setError] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchLoading, setsearchLoading] = useState(false);

  const handleGeneratePDF = () => {
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');

    const pdf = new jsPDF();
    const columns = [
      'Name',
      'Email',
      'Phone',
      'Total Purchase (Tk)',
      'Total Due (Tk)',
      'Last Payback',
    ];

    const imageX = pdf.internal.pageSize.width - 30;
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165;
    const textY = 18;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15);
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(12);
    pdf.text(textX, textY + 6, 'CUSTOMER DATA');

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10;
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns],
      body: data.map((row) => [
        row.name,
        row.email ?? 'N/A',
        row.phone ?? 'N/A',
        row.total_purchase ?? 0,
        row.total_due ?? 0,
        row.last_payback ? dateFormate(row.last_payback) : 'N/A',
      ]),
      styles: {
        font: 'RobotoCondensedBold',
        halign: 'center', // Align text to the center
        cellPadding: 2, // Adjust cell padding for better spacing
        lineColor: [0, 0, 0], // Set border color to black
        lineWidth: 0.5, // Set border width
      },
      headStyles: {
        fillColor: [22, 66, 60], // Set the header background color
        textColor: [255, 255, 255], // Set the header text color to white
        lineColor: [0, 0, 0], // Set header border color to black
        lineWidth: 0.2, // Set header border width
      },
      bodyStyles: {
        fillColor: [255, 255, 255], // Make the background white
        lineColor: [196, 218, 210], // Set body border color to black
        lineWidth: 0.2, // Set body border width
      },
      alternateRowStyles: {
        fillColor: [255, 255, 255], // Ensure no stripes
      },
      margin: { top: 10 },
    });

    pdf.save(`customer-${getCurrentDateTime()}.pdf`);
  };

  const fetchUsers = useCallback(
    async (page) => {
      setLoading(true);
      const response = await Requests.Customer.AllCustomer(page, perPage);
      //showing empty table for now
      setData([]);
      // setData(response.data.data);
      setTotalRows(response.data.total);
      setLoading(false);
    },
    [perPage]
  );

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await Requests.Customer.AllCustomer(page, perPage);
      if (response.status === 200) {
        setData(response.data.data);
        setPerPage(newPerPage);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
      }
    }
  };
  // fetching customer data
  const fetchCustomer = useCallback(async () => {
    setLoading(true);
    try {
      const response = await Requests.Customer.AllCustomer(0, 0);
      if (response && response.status === 200) {
        const data = [];
        for (let i = 0; i < response.data.data?.length; i++) {
          data.push({
            value: response.data.data[i].uid,
            label: response.data.data[i].name,
          });
        }
        setCustomer(data);
        setLoading(false);
      }
    } catch (error) {
      if (error) {
        if (error.response && error.response.status === 401) {
          setError(true);
        }
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsers(1);
    fetchCustomer();
  }, [fetchUsers, fetchCustomer]);

  const handleSearch = async (data) => {
    setsearchLoading(true);
    const response = await Requests.Customer.CustomerSearch(data);
    if (response.data) setData(response.data.data);
    setsearchLoading(false);
  };

  const fetchUsersWithQuery = useCallback(
    async (fromDate, toDate) => {
      setLoading(true);
      const formattedDateFrom = fromDate
        ? moment(fromDate).format('YYYY-MM-DD')
        : '';
      const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      try {
        const response = await Requests.Customer.AllCustomer(
          totalRows,
          perPage,
          formattedDateFrom,
          formattedDateTo
        );
        setData(response.data.data);
        setTotalRows(response.data.total);
      } catch (error) {
        // Handle error
        console.error('Error fetching accounts with query:', error);
        // setError(true);
      } finally {
        setLoading(false);
      }
    },
    [fromDate, toDate, perPage]
  );

  useEffect(() => {
    if (fromDate && toDate) {
      // If date and toDate are set, fetch with query parameters
      fetchUsersWithQuery(fromDate, toDate);
    } else {
      // Otherwise, fetch without query parameters
      fetchCustomer();
    }
  }, [fetchCustomer, fetchUsersWithQuery]);

  // date wise filtering
  const handleDateFilter = (start = null, end = null) => {
    console.log(start, end);
  };

  const columns = [
    {
      name: `${t('Name')}`,
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: `${t('Phone')}`,
      selector: (row) => row.phone ?? 'N/A',
      sortable: true,
    },
    {
      name: `${t('Total Purchase (Tk)')}`,
      selector: (row) => row.total_purchase ?? 0,
      sortable: true,
    },
    {
      name: `${t('Total Due (Tk)')}`,
      selector: (row) => row.total_due ?? 0,
      sortable: true,
    },
    {
      name: `${t('Last Payback')}`,
      selector: (row) =>
        row.last_payback ? dateFormate(row.last_payback) : 'N/A',
      sortable: true,
    },
  ];

  return (
    <div>
      <Layout
        page='report / purchase report'
        message='Purchase report from your shop.'
        container='container-fluid'
        button={
          <div>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      >
        <Main>
          {loading && !error && !data?.length ? <Loader /> : null}
          {!loading && error && !data?.length ? (
            <NetworkError message='Network Error.' />
          ) : !loading && !error && !data?.length ? (
            <NoContent message={'Coming Soon..'} />
          ) : (
            <>
              <Container.Column className='pr-4 mb-2'>
                <div className='d-sm-flex justify-content-between align-items-end pr-2'>
                  {/* From Datepicker */}
                  <div
                    className='pr-sm-2 mb-2 mb-sm-0'
                    style={{ width: '160px' }}
                  >
                    <FormGroup className='mb-0'>
                      <Text className='text-capitalize fs-13 mb-1'>
                        {t('From')}
                      </Text>
                      <DatePicker
                        className='rounded-pill'
                        selected={(data) => setFromDate(data)}
                        deafultValue={fromDate}
                      />
                    </FormGroup>
                  </div>

                  {/* To Datepicker */}
                  <div
                    className='pr-sm-2 mb-2 mb-sm-0'
                    style={{ width: '160px' }}
                  >
                    <FormGroup className='mb-0'>
                      <Text className='text-capitalize fs-13 mb-1'>
                        {t('To')}
                      </Text>
                      <DatePicker
                        className='rounded-pill'
                        selected={(data) => setToDate(data)}
                        deafultValue={toDate}
                      />
                    </FormGroup>
                  </div>

                  {/* Search Field */}
                  <div style={{ flexGrow: 1 }}>
                    <FormGroup className='mb-0'>
                      <Text className='text-capitalize fs-13 mb-1'>
                        {t('Search')}
                      </Text>
                      <input
                        type='text'
                        className='form-control  w-25 rounded-pill'
                        placeholder={t('Search Customer')}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </div>
              </Container.Column>

              <Container.Column className='pl-0'>
                <DataTable
                  columns={columns}
                  data={data}
                  loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  // searchable
                  // placeholder={'Search Supplier'}
                  // search={handleSearch}
                  // suggestion={handleSuggestion}
                  // searchLoading={searchLoading}
                  // clearSearch={() => fetchData(1)}
                />
              </Container.Column>
            </>
          )}
        </Main>
      </Layout>
    </div>
  );
};

export default PurchaseReport;
