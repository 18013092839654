import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkError } from '../../components/501/NetworkError';
import { Container } from '../../components/container/Index';
import { DatePicker } from '../../components/datePicker/Index';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { Layout, Main } from '../../components/layout/Index';
import { Loader } from '../../components/loading/Index';
import { SingleSelect } from '../../components/select/Index';
import { DataTable } from '../../components/table/Index';
import { Text } from '../../components/text/Text';
import { useWindowSize } from '../../components/window/windowSize';
import JsBarcode from 'jsbarcode';
import { getCurrentDateTime } from '../../utils/_heplers';
import { Requests } from '../../utils/Http/Index';
import { GrayButton } from '../../components/button/Index';
import { CornerDownLeft, Printer } from 'react-feather';
import BrandImage from '../../assets/logo.png';
import jsPDF from 'jspdf';
import moment from 'moment';
import { toWords } from 'number-to-words';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Toastify } from '../../components/toastify/Toastify';
import { NoContent } from '../../components/204/NoContent';

const Index = () => {
  const [data, setData] = useState([]);
  const [dokanData, setDokanData] = useState([]);
  const size = useWindowSize();
  const barcodeRef = useRef(null);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [logoData, setLogoData] = useState();
  const [customer, setCustomer] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [isCreate, setCreate] = useState({ show: false, loading: false });
  const templateRef = useRef();
  const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'Delivered', value: 'delivered' },
  ];
  const history = useHistory();

  useEffect(() => {
    if (invoiceNo && barcodeRef.current) {
      JsBarcode(barcodeRef.current, invoiceNo, {
        format: 'CODE128',
        lineColor: '#000',
        width: 2,
        height: 40,
        displayValue: false,
      });
    }
  }, [invoiceNo]);

  // fetch data
  const fetchDokanData = useCallback(async () => {
    try {
      const response = await Requests.Settings.DokanSettingInformation();
      if (response.status === 200) setDokanData(response.data);
      setLogoData(response.data?.dokan?.logo);

      setLoading(false);
    } catch (error) {
      if (error) setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDokanData();
  }, [fetchDokanData]);

  const handleGeneratePDFForList = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);
    let logo = localStorage.getItem('dokanLogo');
    let title = localStorage.getItem('dokanname');
    const pdf = new jsPDF();
    const columns = [
      'Invoice NO.',
      'Customer Name',
      'Phone',
      'Total',
      'Amount Paid',
      'Amount Due',
      'Payment Method',
    ];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(logo, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 20;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, title);

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY + 6, 'ORDERS');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 10, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: data.map((row) => [
        row.uid,
        row.customer_info ? row.customer_info.name : '',
        row.customer_info ? row.customer_info.phone_no : '',
        row.busket_grand_total ?? 0,
        row.amount_paid ?? 0,
        row.amount_due ?? 0,
        row.payment_method ? row.payment_method : 'N/A',
      ]),
      styles: {
        fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
      },
    });

    pdf.save(`order-${getCurrentDateTime()}.pdf`);
  };

  const handleReturn = async (data) => {
    try {
      const response = await Requests.POS.checkProductReturn(data);
      if (response) {
        history.push(`/dashboard/order/return/${data}`);
      } else {
        console.log('called');
      }

      setLoading(false);
    } catch (error) {
      if (error) setLoading(false);
      Toastify.Error('No Returable Product Found!');
    }
  };

  console.log(dokanData, 'dokan');

  const handleGeneratePDF = async () => {
    await generateInvoiceNo(); // Generate the invoice number
    setTimeout(() => {
      window.print(); // Opens the browser's print dialog after the delay
    }, 500); // Opens the browser's print dialog
  };

  const handlePageChange = async (page) => {
    try {
      const response = await Requests.POS.OrderList(page, perPage);
      setData(response.data.data);

      setTotalRows(response.data.meta.total);
      console.log(response.data.meta.total, 'total1');

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);

    try {
      const response = await Requests.POS.OrderList(page, newPerPage);
      setData(response.data.data);

      setTotalRows(response.data.meta.total);
      console.log(response.data.meta.total, 'total2');

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    // setData(response.data.data)
    setLoading(false);
  };

  const fetchData = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const response = await Requests.POS.OrderList(page, perPage);
        setData(response.data.data);
        console.log(perPage, 'perpage');

        setTotalRows(response.data.meta.total);
        console.log(response.data.meta.total, 'total3');

        setLoading(false);
        setError(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    },
    [perPage]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const generateInvoiceNo = async () => {
    try {
      const response = await Requests.POS.GenerateInvoice();
      setInvoiceNo(response?.data?.data?.invoice_number);

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const columns = [
    {
      name: 'Action',
      minWidth: '100px',
      cell: (row) => (
        <div className='d-flex align-items-center justify-content-center '>
          <GrayButton
            type='button'
            className='ml-2 mt-2 mt-sm-0'
            onClick={() => handleReturn(row.uid)}
          >
            <CornerDownLeft size={15} style={{ marginRight: 5 }} />
            <span style={{ fontSize: 13 }}>{t('Return')}</span>
          </GrayButton>
        </div>
      ),
    },
    {
      name: 'Invoice NO.',
      selector: (row) => row.invoice_number || row.uid,
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: (row) => (row.customer_info ? row.customer_info.name : ''),
      sortable: true,
      wrap: true,
    },

    {
      name: 'Customer Phone',
      selector: (row) => (row.customer_info ? row.customer_info.phone : ''),
      sortable: true,
    },

    {
      name: 'Busket Grand Total',
      selector: (row) => row.busket_grand_total,
      sortable: true,
    },
    {
      name: 'Amount Paid',
      selector: (row) => row.amount_paid,
      sortable: true,
    },
    {
      name: 'Amount Due',
      selector: (row) => row.amount_due,
      sortable: true,
    },

    {
      name: 'Payment Method',
      selector: (row) => row.payment_method,
      sortable: true,
    },
  ];

  // Handle search
  const handleSearch = async (query) => {
    setSearching(true);

    setTimeout(() => {
      setSearching(false);
    }, 2000);
  };

  const fetchOrderList = useCallback(async (fromDate, toDate) => {
    setLoading(true);
    const formattedDateFrom = fromDate
      ? moment(fromDate).format('YYYY-MM-DD')
      : '';
    const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
    try {
      const response = await Requests.POS.OrderList(
        totalRows,
        perPage,
        formattedDateFrom,
        formattedDateTo,
        customer
      );
      setData(response.data.data);
      setTotalRows(response.data.meta.total);
    } catch (error) {
      // Handle error
      console.error('Error fetching accounts with query:', error);
      // setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (date || toDate || customer) {
      // If date and toDate are set, fetch with query parameters
      fetchOrderList(date, toDate, customer);
    } else {
      // Otherwise, fetch without query parameters
      fetchData();
    }
  }, [fetchData, fetchOrderList]);

  // fetch mechanics data
  const fetchCustomerData = useCallback(
    async (page) => {
      try {
        const response = await Requests.Customer.AllCustomer(page, perPage);
        if (response.status === 200) {
          // setMechanics(response.data.data)
          let data = [];
          if (response.data.data) {
            for (let i = 0; i < response.data.data?.length; i++) {
              let elements = response.data.data[i];
              data.push({
                label: elements.name ?? '',
                value: elements.uid,
              });
            }
          }
          setCustomers(data);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  // Mechanic Data Call
  useEffect(() => {
    fetchCustomerData(1);
  }, [fetchCustomerData]);

  const fetchOrdersWithQuery = useCallback(async (date, toDate, customer) => {
    setLoading(true);
    const formattedDateFrom = date ? moment(date).format('YYYY-MM-DD') : '';
    const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
    try {
      const response = await Requests.POS.OrderList(
        totalRows,
        perPage,
        formattedDateFrom,
        formattedDateTo,
        customer
      );
      setData(response.data.data);
      setTotalRows(response.data.total);
    } catch (error) {
      // Handle error
      console.error('Error fetching accounts with query:', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (date && toDate) {
      // If date and toDate are set, fetch with query parameters
      fetchOrdersWithQuery(date, toDate, customer);
    } else if (customer) {
      // If date and toDate are set, fetch with query parameters
      fetchOrdersWithQuery(date, toDate, customer);
    } else {
      // Otherwise, fetch without query parameters
      fetchData();
    }
  }, [fetchData, fetchOrdersWithQuery, customer, date, toDate]);

  return (
    <div>
      <Layout
        page='Product return replace / product return'
        message='Customer list those are eligible to product return and replace'
        container='container-fluid'
        printable
        printData={'No data'}
        otherPage
        pageLink='/dashboard/inventory/product/new'
        button={
          <div>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDFForList}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      >
        <Main>
          <Container.Column className='pr-4 mt-2 mb-2'>
            <div className='d-sm-flex justify-content-end pr-2'>
              {/* Mechanic options */}
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 200 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Select Customer')}
                  </Text>
                  <SingleSelect
                    borderRadius={30}
                    placeholder='customer'
                    options={customers}
                    value={(event) => setCustomer(event.value)}
                  />
                </FormGroup>
              </div>

              {/* From Datepicker */}
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 160 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('From')}
                  </Text>
                  <DatePicker
                    className='rounded-pill'
                    selected={(data) => setDate(data)}
                    deafultValue={date}
                  />
                </FormGroup>
              </div>

              {/* To Datepicker */}
              <div style={{ width: size.width <= 576 ? '100%' : 160 }}>
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>{t('To')}</Text>
                  <DatePicker
                    className='rounded-pill'
                    selected={(data) => setToDate(data)}
                    deafultValue={toDate}
                  />
                </FormGroup>
              </div>
            </div>
          </Container.Column>
          <Container.Column>
            {loading && !data?.length ? <Loader /> : null}
            {!loading && error && !data?.length ? (
              <NetworkError message='Network Error.' />
            ) : !loading && !data?.length ? (
              <NoContent message='No Content.' />
            ) : (
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                search={handleSearch}
                searching={searching}
                pagination={true}
                paginationServer={true}
              />
            )}
          </Container.Column>
        </Main>
      </Layout>
    </div>
  );
};

export default Index;
