import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

// for adding product
const DokanProductAdd = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/products`, data, config);
};
const DokanProductReturn = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/return-product`, data, config);
};

const DokanProductList = async (
  page = 1,
  perpage,
  formattedDateFrom = '',
  formattedDateTo = '',
  category = '',
  brand = '',
  supplier = ''
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/products?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&supplier_uid=${supplier}&category_uid=${category}&brand_uid=${brand}&from=${formattedDateFrom}&to=${formattedDateTo}`,
    config
  );
};

const DokanPosProductList = async (page = 0, perpage = 0) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/pos-products?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
    // &page=${page}&per_page=${perpage}
  );
};

const ReturnProductList = async (
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/return-product?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
    // &page=${page}&per_page=${perpage}
  );
};

const DokanProductUpdate = async (data, uid) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/products/${uid}`, data, config);
};

const DokanSingleProductShow = async (uid) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/products/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const DokanProductDelete = async (uid) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.delete(
    `${API}/products/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const DokanProductSearch = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/products?dokan_uid=${localStorage.getItem('dokanuid')}&q=${search}`,
    config
  );
};

const DokanPosProductSearch = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/pos-products?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}`,
    config
  );
};

const DokanPosProductByBarcodeImei = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/pos-product-by-code-imei?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}`,
    config
  );
};

const Product = {
  DokanProductAdd,
  DokanProductReturn,
  DokanProductList,
  DokanProductUpdate,
  DokanSingleProductShow,
  DokanProductDelete,
  DokanProductSearch,
  DokanPosProductSearch,
  DokanPosProductByBarcodeImei,
  DokanPosProductList,
  ReturnProductList,
};

export default Product;
