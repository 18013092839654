import React from 'react';
import BestSellingProducts from './ProductTable';
import { formatAmount } from '../../utils/_heplers';

const SupplierCards = ({ dashBoardData }) => {
  return (
    <div className='row g-3 my-3'>
      {/* left Column - Full Height Card */}
      <div className='col-md-6'>
        <div
          className='card text-white h-100 d-flex flex-column justify-content-between'
          style={{
            background: 'linear-gradient(45deg, #071952, #46458C)', // Custom gradient for this card
          }}
        >
          <BestSellingProducts dashBoardData={dashBoardData} />
        </div>
      </div>
      {/* right Column - 6 Columns */}
      <div className='col-md-6'>
        <div className='row'>
          {/* Card 1 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #8E7AB5, #B784B7)', // Gradient for success card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Brands</h5>
                    <h3>{dashBoardData?.dashboard_info?.total_brand}</h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      fill='currentColor'
                      class='bi bi-microsoft'
                      viewBox='0 0 16 16'
                    >
                      <path d='M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className='col-md-4 mb-3'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #007bff, #0056b3)', // Gradient for primary card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Category</h5>
                    <h3>{dashBoardData?.dashboard_info?.total_category}</h3>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='currentColor'
                    class='bi bi-tags'
                    viewBox='0 0 16 16'
                  >
                    <path d='M3 2v4.586l7 7L14.586 9l-7-7zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586z' />
                    <path d='M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1z' />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className='col-md-4'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #4F6F52, #739072)', // Gradient for warning card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Product Type</h5>
                    <h3>{dashBoardData?.dashboard_info?.total_product_type}</h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      fill='currentColor'
                      class='bi bi-box2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M2.95.4a1 1 0 0 1 .8-.4h8.5a1 1 0 0 1 .8.4l2.85 3.8a.5.5 0 0 1 .1.3V15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4.5a.5.5 0 0 1 .1-.3zM7.5 1H3.75L1.5 4h6zm1 0v3h6l-2.25-3zM15 5H1v10h14z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className='col-md-4'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #0A4D68, #088395)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Product</h5>
                    <h3>{dashBoardData?.dashboard_info?.total_product}</h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-box-fill'
                      viewBox='0 0 16 16'
                    >
                      <path
                        fill-rule='evenodd'
                        d='M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Card 1 */}
          <div className='col-md-4 mb-3 '>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #8E7AB5, #B784B7)', // Gradient for success card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Suppliers</h5>
                    <h3>{dashBoardData?.dashboard_info?.total_supplier}</h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-person-video3'
                      viewBox='0 0 16 16'
                    >
                      <path d='M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2' />
                      <path d='M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783Q16 12.312 16 12V4a2 2 0 0 0-2-2z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className='col-md-4 mb-3 '>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #4F6F52, #739072)', // Gradient for primary card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Supplier Purchase</h5>
                    <h3>
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_supplier_purchase
                      )}
                    </h3>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='40'
                    height='40'
                    fill='currentColor'
                    class='bi bi-currency-exchange'
                    viewBox='0 0 16 16'
                  >
                    <path d='M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z' />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #643843, #99627A)', // Gradient for warning card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Supplier Paid</h5>
                    <h3>
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_supplier_paid
                      )}
                    </h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-wallet2'
                      viewBox='0 0 16 16'
                    >
                      <path d='M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className='col-md-4'>
            <div
              className='card text-white'
              style={{
                background: 'linear-gradient(45deg, #921A40, #C75B7A)', // Gradient for danger card
              }}
            >
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div>
                    <h5> Supplier Due</h5>
                    <h3>
                      {' '}
                      ৳{' '}
                      {formatAmount(
                        dashBoardData?.dashboard_info?.total_supplier_due
                      )}
                    </h3>
                  </div>
                  <div className=''>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='40'
                      height='40'
                      fill='currentColor'
                      class='bi bi-cash'
                      viewBox='0 0 16 16'
                    >
                      <path d='M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4' />
                      <path d='M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2z' />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card 4 */}
        </div>
      </div>
    </div>
  );
};

export default SupplierCards;
